import { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import {
  Stack,
  Button
} from "@mui/material";
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { ComponentWrapper } from "../../../components/styledComponents";
import {
  DataGridPro, useGridApiRef
} from "@mui/x-data-grid-pro";
import ExportSummaryByGlCode from './ExportSummaryByGlCode';
import { FSCCodes, miscReceivables, miscPayables } from '../CommonFn'
import { getGLCodes } from "../../../store/actions/rateBookActions";

export default function SummaryByGlCode(props) {
  const { activeTab, rawData, locationNumber, isLoading, filterParams } = props;
  const [rows, setRows] = useState([]);
  const [totals, setTotals] = useState({
    receivables: 0,
    payables: 0,
    miscReceivables: 0,
    miscExpenses: 0,
  });
  const [isLoadingActive, setIsLoadingActive] = useState(false);
  const [glCodeData, setGlCodeData] = useState([]);
  const apiRef = useGridApiRef();
  const rateBookReducer = useSelector((state) => state.rateBookReducer.rateBookReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!rateBookReducer?.GLCodeDataAll?.length) {
      setIsLoadingActive(true);
      dispatch(getGLCodes(true)).then((res) => {
        setIsLoadingActive(false);
        setGlCodeData(res);
      });
    } else {
      setGlCodeData(rateBookReducer.GLCodeDataAll);
    }
  }, [activeTab, rawData, locationNumber]);

  useEffect(() => {
    setRows(processDataForGlCode(rawData));
  }, [glCodeData]);

  const processDataForGlCode = (inputData) => {
    const totals = {
      receivables: 0,
      payables: 0,
      miscReceivables: 0,
      miscExpenses: 0,
    };

    const dataByType = {
      receivables: [],
      payables: [],
      miscReceivables: [],
      miscExpenses: [],
    };

    const processedGLCodes = new Set();

    glCodeData.forEach(({ glcode, glDescription, glType }) => {
      if (!processedGLCodes.has(glcode)) {
        processedGLCodes.add(glcode);

        const entry = {
          glCode: glcode,
          glDescription,
          amount: 0,
          ratePercent: 0,
        };

        if (glType === "P") dataByType.payables.push(entry);
        else if (glType === "R") dataByType.receivables.push(entry);
        else if (glType === "MR") dataByType.miscReceivables.push(entry);
        else if (glType === "MP") dataByType.miscExpenses.push(entry);
      }
    });

    inputData.forEach((record) => {
      let rates = record.rates;

      if (filterParams.excludeFSC) {
        rates = rates.filter((rate) => !FSCCodes.includes(rate.glCode));
      }

      rates.forEach(({ glCode, amount, payableReceivable }) => {
        const updateTotals = (array, totalKey, isMisc) => {
          const item = array.find((entry) => entry.glCode === glCode);
          if (item && (isMisc || item.glCode === glCode)) {
            item.amount += Number(amount);
            totals[totalKey] += Number(amount);
          }
        };

        if (payableReceivable === "P") {
          if (miscPayables.includes(glCode)) {
            updateTotals(dataByType.miscExpenses, "miscExpenses", true);
          } else {
            updateTotals(dataByType.payables, "payables", false);
          }
        } else if (payableReceivable === "R") {
          if (miscReceivables.includes(glCode)) {
            updateTotals(dataByType.miscReceivables, "miscReceivables", true);
          } else {
            updateTotals(dataByType.receivables, "receivables", false);
          }
        }
      });
    });

    // Calculate percentages
    const totalReceivablesForReceivables = totals.receivables + totals.miscReceivables;
    const totalReceivablesForPayables = totals.receivables; // Exclude miscReceivables for payables percentage

    ["payables", "receivables", "miscReceivables", "miscExpenses"].forEach((key) => {
      dataByType[key].forEach((item) => {
        if (key === "payables") {
          item.ratePercent = totalReceivablesForPayables
            ? ((item.amount / totalReceivablesForPayables) * 100).toFixed(2)
            : 0;
        } else {
          item.ratePercent = totalReceivablesForReceivables
            ? ((item.amount / totalReceivablesForReceivables) * 100).toFixed(2)
            : 0;
        }
      });
    });

    setTotals(totals);
    return createRows(dataByType);
  };

  const createRows = (dataByType) => {
    const maxLength = Math.max(
      dataByType.receivables.length,
      dataByType.payables.length,
      dataByType.miscReceivables.length,
      dataByType.miscExpenses.length
    );

    return Array.from({ length: maxLength }, (_, i) => ({
      id: `row-${i}`,
      ...mapRowFields(dataByType, i),
    }));
  };

  const mapRowFields = (dataByType, index) => {
    const mapFields = (type) =>
      dataByType[type][index]
        ? {
          [`${type}`]: dataByType[type][index].glCode || null,
          [`${type}Desc`]: dataByType[type][index].glDescription || null,
          [`${type}Amt`]: `$${dataByType[type][index].amount.toFixed(2)}` || null,
          [`${type}Percent`]: `${dataByType[type][index].ratePercent}%` || null,
        }
        : {};

    return {
      ...mapFields("receivables"),
      ...mapFields("payables"),
      ...mapFields("miscReceivables"),
      ...mapFields("miscExpenses"),
    };
  };

  const pinnedRows = {
    bottom: [
      {
        id: "total-rows",
        receivables: "Receivables",
        receivablesAmt: `$${totals.receivables.toFixed(2)}`,
        receivablesPercent: `${((totals.receivables / (totals.receivables + totals.miscReceivables)) * 100).toFixed(2)}%`,
        payables: "Payables",
        payablesAmt: `$${totals.payables.toFixed(2)}`,
        payablesPercent: `${((totals.payables / (totals.receivables)) * 100).toFixed(2)}%`,
        miscReceivables: "Misc Receivables",
        miscReceivablesAmt: `$${totals.miscReceivables.toFixed(2)}`,
        miscReceivablesPercent: `${((totals.miscReceivables / (totals.receivables + totals.miscReceivables)) * 100).toFixed(2)}%`,
        miscExpenses: "Misc Expenses",
        miscExpensesAmt: `$${totals.miscExpenses.toFixed(2)}`,
        miscExpensesPercent: `${((totals.miscExpenses / (totals.receivables + totals.miscReceivables)) * 100).toFixed(2)}%`,
      },
      {
        id: "grand-total-rows",
        receivables: "Grand Total",
        receivablesAmt: `$${(totals.receivables + totals.miscReceivables).toFixed(2)}`,
        payablesAmt: `$${(totals.payables + totals.miscExpenses).toFixed(2)}`,
      },
    ],
  };


  const tableColumns = [
    {
      field: 'receivables',
      headerName: 'Receivables',
      flex: 1.2,
      renderCell: (params) => params.value ? params.value : null
    },
    {
      field: 'receivablesDesc',
      headerName: 'Description',
      flex: 2,
      renderCell: (params) => params.value ? params.value : null
    },
    {
      field: 'receivablesAmt',
      headerName: '$',
      flex: 1,
      renderCell: (params) => params.value ? params.value : null
    },
    {
      field: 'receivablesPercent',
      headerName: '%',
      flex: 0.8,
      renderCell: (params) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            height: '100%' // Ensure the container spans the cell height
          }}
        >
          <span
            style={{
              flex: 1,
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
          >
            {params.value ? params.value : null}
          </span>
          <span
            style={{
              borderRight: '2px solid #ccc',
              height: '100%', // Match the height of the parent container
              marginLeft: '2px'
            }}
          ></span>
        </div>
      ),
    },
    {
      field: 'payables',
      headerName: 'Payables',
      flex: 1.2,
      renderCell: (params) => params.value ? params.value : null
    },
    {
      field: 'payablesDesc',
      headerName: 'Description',
      flex: 2,
      renderCell: (params) => params.value ? params.value : null
    },
    {
      field: 'payablesAmt',
      headerName: '$',
      flex: 1,
      renderCell: (params) => params.value ? params.value : null
    },
    {
      field: 'payablesPercent',
      headerName: '%',
      flex: 0.8,
      renderCell: (params) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            height: '100%' // Ensure the container spans the cell height
          }}
        >
          <span
            style={{
              flex: 1,
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
          >
            {params.value ? params.value : null}
          </span>
          <span
            style={{
              borderRight: '2px solid #ccc',
              height: '100%', // Match the height of the parent container
              marginLeft: '2px'
            }}
          ></span>
        </div>
      ),
    },
    {
      field: 'miscReceivables',
      headerName: 'Misc Receivables',
      flex: 1.2,
      renderCell: (params) => params.value ? params.value : null
    },
    {
      field: 'miscReceivablesDesc',
      headerName: 'Description',
      flex: 2,
      renderCell: (params) => params.value ? params.value : null
    },
    {
      field: 'miscReceivablesAmt',
      headerName: '$',
      flex: 1,
      renderCell: (params) => params.value ? params.value : null
    },
    {
      field: 'miscReceivablesPercent',
      headerName: '%',
      flex: 0.8,
      renderCell: (params) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            height: '100%' // Ensure the container spans the cell height
          }}
        >
          <span
            style={{
              flex: 1,
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
          >
            {params.value ? params.value : null}
          </span>
          <span
            style={{
              borderRight: '2px solid #ccc',
              height: '100%', // Match the height of the parent container
              marginLeft: '2px'
            }}
          ></span>
        </div>
      ),
    },
    {
      field: 'miscExpenses',
      headerName: 'Misc Expenses',
      flex: 1.2,
      renderCell: (params) => params.value ? params.value : null
    },
    {
      field: 'miscExpensesDesc',
      headerName: 'Description',
      flex: 2,
      renderCell: (params) => params.value ? params.value : null
    },
    {
      field: 'miscExpensesAmt',
      headerName: '$',
      flex: 1,
      renderCell: (params) => params.value ? params.value : null
    },
    {
      field: 'miscExpensesPercent',
      headerName: '%',
      flex: 0.8,
      renderCell: (params) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            height: '100%' // Ensure the container spans the cell height
          }}
        >
          <span
            style={{
              flex: 1,
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
          >
            {params.value ? params.value : null}
          </span>
          <span
            style={{
              borderRight: '2px solid #ccc',
              height: '100%', // Match the height of the parent container
              marginLeft: '2px'
            }}
          ></span>
        </div>
      ),
    }
  ];


  const handleExportCsv = () => {
    apiRef.current.exportDataAsCsv();
  };

  return (
    <ComponentWrapper>
      <Stack
        direction="row"
        justifyContent="flex-end"
        sx={{
          position: 'absolute',
          //bottom:0,
          right: "1%",
          zIndex: 9999,
          marginTop: "-2%"
        }}
      >
        <Button
          variant="text"
          size="small"
          onClick={handleExportCsv}
          startIcon={<TextSnippetIcon color="primary" />}
          color={`primary`}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          CSV
        </Button>
        <Button
          variant="text"
          size="small"
          onClick={ExportSummaryByGlCode([...rows, ...pinnedRows.bottom])}
          startIcon={<PictureAsPdfIcon color="primary" />}
          color={`primary`}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          PDF
        </Button>
      </Stack>
      <Stack
        //marginTop={2}
        sx={{
          height: window.innerHeight - 270,
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "rgba(11, 67, 135, 1)",
            color: "white",
          },
          "& .isSubtotal": {
            fontWeight: "bold",
            // color: "blue",
            backgroundColor: "#E8E8E8 !important",
            // backgroundColor: "#F0F0F0 !important",
          },

          "& .pinned-row": {
            backgroundColor: "#f0f0f0",  /* Light gray background */
            fontWeight: "bold",          /* Bold text */
            color: "#000000"            /* Black text */
          },
          "& .pinned-row .MuiDataGrid-cell": {
            borderTop: "2px solid #000000"
          }

        }}
      >
        <DataGridPro
          apiRef={apiRef}
          hideFooter={true}
          rows={rows}
          columns={tableColumns}
          loading={isLoadingActive || isLoading}
          pinnedRows={pinnedRows}
          getRowId={(row) => row.id || `generated-${Math.random()}`}
          getRowClassName={(params) =>
            ['total-rows', 'grand-total-rows'].includes(params.id) ? 'pinned-row' : ''
          }
          sx={{
            "& .MuiDataGrid-columnSeparator": {
              display: "none", // Hide separators to visually reduce space
            },
            "& .MuiDataGrid-cell": {
              padding: "0 4px", // Adjust padding inside cells
            },
            "& .MuiDataGrid-columnHeader": {
              padding: "0 4px", // Adjust padding inside headers
            },
          }}
        />
      </Stack>
    </ComponentWrapper>
  );
}
