export function getOrderType(type) {
        switch (type) {
            case 'DOMINB':
            case 'IMPORT':
            case 'INBOUND':
                return 'I';
            case 'DOMOUT':
            case 'EXPORT':
            case 'OUTBOUND':
                return 'O';
            default:
                return 'NA';
            }
        }