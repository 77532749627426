export enum rateBookTypes {
    SET_LOADING = "SET_LOADING",
    GET_RATEBOOK = 'GET_RATEBOOK',
    ADD_RATEBOOK = 'ADD_RATEBOOK',
    EDIT_RATEBOOK = 'EDIT_RATEBOOK',
    DELETE_RATEBOOK = 'DELETE_RATEBOOK',
    GET_GL_CODE = "GET_GL_CODE",
    GET_GL_CODE_ALL = "GET_GL_CODE_ALL",
    GET_STATES = "GET_STATES",
    GET_CITIES = "GET_CITIES",
    GET_EXPORT_VALUE = "GET_EXPORT_VALUE",
    GET_IMPORT_VALUE="GET_IMPORT_VALUE",
    GET_HEADER_LOCATION_VALUE="GET_HEADER_LOCATION_VALUE"
}