import React, { Component, useState, useEffect, useRef, createRef } from "react";
import { Box, Stack, Typography, Snackbar, Alert, Tabs, Tab } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { ComponentWrapper } from "../../../components/styledComponents";
import BillingFilterSection from './BillingFilterSection';
import DetailedSummary from './DetailedSummary';
import SummaryByControl from './SummaryByControl';
import SummaryByDay from './SummaryByDay';
import SummaryByGlCode from './SummaryByGlCode'
import {
    getBillingReportData
} from "../../../store/actions/reportActions";
import { getGLCodes } from "../../../store/actions/rateBookActions";

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

export default function Billing({ navigation }) {
    const dispatch = useDispatch();
    const ordersLocation = useSelector(
        ({ orderReducer }) => orderReducer.ordersLocation
    );

    const [activeTab, setActiveTab] = useState(0);
    const [snackbar, setSnackbar] = useState(null);
    const [totalRecords, setTotalRecords] = useState([0, 0]);
    const [rawData, setRawData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [filterParams, setFilterParams] = useState(false);

    useEffect(() => {
        if(ordersLocation?.locationNumber > 0){
            //fetchDataFromServer(ordersLocation.locationNumber, filterData.queryParams);
            dispatch(getGLCodes(true))
        }
    }, [ordersLocation?.locationNumber])

    const fetchDataFromServer = (locationNumber, filters) => {
            setFilterParams(filters)
            setIsLoading(true)
            dispatch(getBillingReportData(locationNumber, filters)).then((res) => {
                setIsLoading(false)
                setRawData(res?.billingSummary || []);
                setTotalRecords([res?.data?.billingSummary.length, res?.data?.billingSummary.length])
            }).catch((err) => setIsLoading(false))
        }

    return (
        <ComponentWrapper>
            <Box
                sx={{
                    padding: 1
                }}
            >
                <Stack >
                    <BillingFilterSection
                        onApplyFilters={(params) => fetchDataFromServer(ordersLocation?.locationNumber, params)}
                        onClearFilters={() => fetchDataFromServer(ordersLocation?.locationNumber, {})}
                    />
                </Stack>  
                {/* <Stack direction="row" justifyContent={`space-between`} mt={2}>
                    <Stack> */}
                    <Box mt={1} sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={activeTab} onChange={(event, newValue) => setActiveTab(newValue)} aria-label="basic tabs example">
                            <Tab
                                label="Summary By Control#" id='simple-tab-0'
                                sx={(activeTab === 0) ? {
                                    backgroundColor: "#0911A7",
                                    color: "white !important",
                                    fontWeight: 500,
                                    textTransform: 'capitalize',
                                    borderRadius: '8%'
                                } : {}}
                            />
                            <Tab
                                label="Detail by Control #" id='simple-tab-1'
                                sx={(activeTab === 1) ? {
                                    backgroundColor: "#0911A7",
                                    color: "white !important",
                                    fontWeight: 500,
                                    textTransform: 'capitalize',
                                    borderRadius: '8%'
                                } : {}}
                            />
                            <Tab
                                label="Daily Summary" id='simple-tab-2'
                                sx={(activeTab === 2) ? {
                                    backgroundColor: "#0911A7",
                                    color: "white !important",
                                    fontWeight: 500,
                                    textTransform: 'capitalize',
                                    borderRadius: '8%'
                                } : {}}
                            />
                            <Tab
                                label="Summary By GL Code" id='simple-tab-3'
                                sx={(activeTab === 3) ? {
                                    backgroundColor: "#0911A7",
                                    color: "white !important",
                                    fontWeight: 500,
                                    textTransform: 'capitalize',
                                    borderRadius: '8%'
                                } : {}}
                            />
                        </Tabs>
                    </Box>
                    {/* </Stack>          
                </Stack> */}
                <CustomTabPanel value={activeTab} index={0}>
                    <SummaryByControl
                        setTotalRecords={val => setTotalRecords(val)}
                        rawData={rawData}
                        locationNumber={ordersLocation?.locationNumber || 0}
                        isLoading={isLoading}
                        filterParams={filterParams}
                    />
                </CustomTabPanel>
                <CustomTabPanel value={activeTab} index={1}>
                    <DetailedSummary
                        setTotalRecords={val => setTotalRecords(val)} activeTab={activeTab}
                        rawData={rawData}
                        locationNumber={ordersLocation?.locationNumber || 0}
                        isLoading={isLoading}
                        filterParams={filterParams}
                    />
                </CustomTabPanel>
                <CustomTabPanel value={activeTab} index={2}>
                    <SummaryByDay
                        setTotalRecords={val => setTotalRecords(val)} activeTab={activeTab}
                        rawData={rawData}
                        locationNumber={ordersLocation?.locationNumber || 0}
                        isLoading={isLoading}
                        filterParams={filterParams}
                    />
                </CustomTabPanel>
                <CustomTabPanel value={activeTab} index={3}>
                    <SummaryByGlCode
                        setTotalRecords={val => setTotalRecords(val)} activeTab={activeTab}
                        rawData={rawData}
                        locationNumber={ordersLocation?.locationNumber || 0}
                        isLoading={isLoading}
                        filterParams={filterParams}
                    />
                </CustomTabPanel>

            </Box>
            {
                !!snackbar && (
                    <Snackbar
                        open
                        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                        onClose={() => setSnackbar(null)}
                        autoHideDuration={3000}
                    // bodyStyle={{ height: 'auto', lineHeight: '28px', padding: 24, whiteSpace: 'pre-line' }}
                    >
                        <Alert {...snackbar} onClose={() => setSnackbar(null)} />
                    </Snackbar>
                )
            }
        </ComponentWrapper>
    );
}