import moment from 'moment';
import dayjs from 'dayjs';
/**
 * Convert military time to hh:mm
 * @param {*} timeVal
 * @returns
 */
export const convertMilitaryTimeToHHMM = (timeVal) => {
    if (!timeVal || timeVal === 0)
        return "";

    // Convert timeVal to string for manipulation
    let timeString = timeVal.toString();

    // Pad with zeros if the length is less than 4
    while (timeString.length < 4) {
        timeString = "0" + timeString;
    }

    // Extract hours and minutes parts
    const hours = timeString.substring(0, 2);
    const minutes = timeString.substring(2);
    // Return formatted time
    return `${hours}:${minutes}`;
}

/**
 * Convert Military date to YMD
 */
 export const convertMilitaryDateToYMD = (dateVal) => {
    if (dateVal === 0 || !dateVal)
        return ''
    let date = dateVal.toString().substring(1)
    date = `20${date.substring(0, 2)}-${date.substring(2, 4)}-${date.substring(4)}`
    if (moment(date, 'YYYY-MM-DD', true).isValid()) {
        return moment(date).format('MM/DD/YY');
    }
    return '';
}

export const convertMilitaryDateToDDMMYY = (dateVal) => {
    if (dateVal === 0 || !dateVal)
        return 'NA'
    let date = dateVal.toString().substring(1)
    date = `20${date.substring(0, 2)}-${date.substring(2, 4)}-${date.substring(4)}`
    if (moment(date, 'YYYY-MM-DD', true).isValid()) {
        return moment(date).format('MM/DD/YY');
    }
    return 'N/A';
}

export const convertMilitaryToYMD = (dateVal) => {
    if (dateVal === 0 || !dateVal)
        return ''
    let date = dateVal.toString().substring(1)
    date = `20${date.substring(0, 2)}-${date.substring(2, 4)}-${date.substring(4)}`
    if (moment(date, 'YYYY-MM-DD', true).isValid()) {
        return moment(date).format('YYYY-MM-DD');
    }
    return '';
}

/**
 * Convert YMD date to MDY
 * @param {*} dateVal
 * @returns
 */
export const convertYMDToMDY = (dateVal) => {
    if (dateVal === 0 || !dateVal)
        return 0
    if (moment(dateVal, 'YYYY-MM-DD', true).isValid()) {
        return moment(dateVal).format('MM/DD/YY');
    }
    return dateVal;
}

/**
 * Convert MDY date to YMD
 * @param {*} dateVal
 * @returns
 */
export const convertMDYToYMD = (dateVal) => {
    if (dateVal === 0 || !dateVal)
        return 0
    if (moment(dateVal, 'YYYY-MM-DD', true).isValid() === false) {
        return moment(dateVal).format('YYYY-MM-DD');
    }
    return dateVal;
}

/**
 * Sanitize string & trim characters to match database field size
 */
 export const sanitizeStringForDBField = (str, length, removeSpecialChars=false) => {
    if (str === null || typeof str === 'undefined') {
        return '';
    }
    let newStr = str.substring(0, length);
    if(removeSpecialChars){
        return newStr.replace(/[^a-zA-Z0-9]/g, '');
    }
    return newStr;
}

export const isNullOrZeroOrEmptyOrUndefined = (value) => {
    // Check if the value is undefined, null, or an empty string
    if (value === undefined || value === null || value === '') {
      return true;
    }

    // Check if the value is zero (you can include zero in the conditions if needed)
    if (typeof value === 'number' && value === 0) {
      return true;
    }

    // Check if the value is an empty array
    if (Array.isArray(value) && value.length === 0) {
      return true;
    }

    // Check if the value is an empty object
    if (typeof value === 'object' && !Array.isArray(value) && Object.keys(value).length === 0) {
      return true;
    }

    // If none of the conditions match, return false
    return false;
  }

export const convertDateToCentury = (dateVal) => {
    // if (!dateVal || dateVal.trim() === "") {
    //     return "";
    // }
    const momentDate = moment(dateVal, ['MM-DD-YY', 'MM/DD/YY', 'MM/DD/YYYY','1YYMMDD','YYYY-MM-DD'], true);

    if (momentDate.isValid()) {
        return "1" + momentDate.format('YYMMDD');
    } else {
        return "";
    }

}


export const formatedDateRangeFunc = (dateValueStr) => {
    // Check if dateValueStr exists and is in the expected format
    if (!dateValueStr || !dateValueStr.includes('|')) {
      return '';
    }
  
    // Split the dateValueStr to get the start and end dates
    const [startDateStr, endDateStr] = dateValueStr.split('|');
  
    // Extract the actual date by removing the leading '1'
    const startDate = startDateStr ? dayjs(startDateStr.substring(1), 'YYMMDD') : null;
    const endDate = endDateStr ? dayjs(endDateStr.substring(1), 'YYMMDD') : null;
  
    // Format the dates in MM/DD/YY format
    const formattedStartDate = startDate ? startDate.format('MM/DD/YY') : '';
    const formattedEndDate = endDate ? endDate.format('MM/DD/YY') : '';
  
    // Return the formatted date range in MM/DD/YY - MM/DD/YY format
    return `${formattedStartDate} - ${formattedEndDate}`;
  };

  export const formatDateRange = (dateValue) => {
    const formatSingleDate = (date) => {
        return date ? "1" + moment(date.toDate()).format('YYMMDD') : '';
    };

    const formattedStartDate = formatSingleDate(dateValue[0]);
    const formattedEndDate = formatSingleDate(dateValue[1]);

    return { formattedStartDate, formattedEndDate };
};

export const convertYMDToMonthName = (dateVal, hideYear=true) => {
    if (dateVal === 0 || !dateVal)
        return 0
    if (moment(dateVal, 'YYYY-MM-DD', true).isValid()) {
        return hideYear?moment(dateVal).format("MMMM D"):moment(dateVal).format("MMMM D, YY");
    }
    return dateVal;
}

export const validateDate = (dateVal, dateFormat='military') => {
    switch (dateFormat) {
        case 'military':
            if (dateVal === 0 || !dateVal)
                return false
            let date = dateVal.toString().substring(1)
            date = `20${date.substring(0, 2)}-${date.substring(2, 4)}-${date.substring(4)}`
            if (moment(date, 'YYYY-MM-DD', true).isValid()) {
                return true;
            }
            return false;
            break;
    
        default:
            break;
    }
}

export const sanitizeString = (str) => {
    return (typeof str === 'string' ? str.trim() : '') === '' ? 'N/A' : str;
}

export const formatCurrency = (amount) => {
    // Convert the amount to a fixed decimal format with 2 places
    // const formattedAmount = Math.abs(amount).toFixed(2);
    const numericAmount = parseFloat(amount);
    if (isNaN(numericAmount)) {
        return formatUSD(0); // Default to $0.00 for invalid input
    }
    
    // Check if the amount is negative and add a minus sign before the dollar sign if so
    // return formattedAmount < 0 ? `-${formatUSD(formattedAmount)}` : `${formatUSD(formattedAmount)}`;
    return numericAmount < 0 
    ? `-${formatUSD(Math.abs(numericAmount))}` 
    : formatUSD(numericAmount);
    
}

export const formatPercentageNum = (value) => {
    const formattedNumber = Math.abs(value).toFixed(2);
    
    // Check if the amount is negative and add a minus sign before the dollar sign if so
    return value < 0 ? `-${formatPercentage(formattedNumber)}` : `${formatPercentage(formattedNumber)}`;
}

export const formatUSD = (amount) => {
    //return new Intl.NumberFormat("en-US", {
    return Number(amount).toLocaleString('en-US', {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }); //.format(amount);
}

export const formatPercentage = (value) => {
    return (value / 100).toLocaleString('en-US', {
        style: 'percent',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
    // return new Intl.NumberFormat("en-US", {
    //   style: "percent",
    //   minimumFractionDigits: 2,
    //   maximumFractionDigits: 2
    // }).format(value / 100); // Divide by 100 to convert from whole number to percentage
}

export function formatToK(number) {
    if (typeof number !== 'number' || isNaN(number)) {
      console.log('Input must be a valid number');
      return false;
    }
  
    if (number >= 1000) {
      return (number / 1000).toFixed(1).replace(/\.0$/, '') + 'k'; // Converts to "k" format
    }
  
    return number.toString(); // Return as is if less than 1000
  }

export const splitOrderNotes = (notes) => {
    if (notes.length === 0) {
      return notes; // Return empty array if notes is empty
    }
  
    const result = [];
  
    notes.forEach(noteObj => {
      const { userId, note, time } = noteObj;
  
      // Split the note into chunks if its length is greater than 75
      if (note.length > 75) {
        let currentNote = note;
        while (currentNote.length > 75) {
          // Find the last space within the first 75 characters
          const splitIndex = currentNote.lastIndexOf(' ', 75);
  
          // If there's no space (in case the note is a single long word), break at 75 characters
          if (splitIndex === -1) {
            result.push({
              userId,
              note: currentNote.slice(0, 75),
              time
            });
            currentNote = currentNote.slice(75); // Continue with the remaining part
          } else {
            result.push({
              userId,
              note: currentNote.slice(0, splitIndex),
              time
            });
            currentNote = currentNote.slice(splitIndex + 1); // Skip the space and continue
          }
        }
  
        // Push the remaining part of the note (if any)
        if (currentNote.length > 0) {
          result.push({
            userId,
            note: currentNote,
            time
          });
        }
      } else {
        // If the note is not longer than 75 characters, just push it as is
        result.push(noteObj);
      }
    });
  
    return result;
  };
