import moment from 'moment';
import {splitOrderNotes} from "../../../utils/global-functions"

function convertDatetime(inputDatetimeStr) {
  // Create a Date object from the input datetime string
  const dt = new Date(inputDatetimeStr);

  // Get the components of the date
  const month = ('0' + (dt.getMonth() + 1)).slice(-2); // Months are zero-based
  const day = ('0' + dt.getDate()).slice(-2);
  const year = dt.getFullYear().toString().slice(-2);
  const hours = ('0' + dt.getHours()).slice(-2);
  const minutes = ('0' + dt.getMinutes()).slice(-2);

  // Format the datetime to the desired format
  const outputDatetimeStr = `${month}/${day}/${year} ${hours}:${minutes}`;
  return outputDatetimeStr;
}

export const maptoEdiOrderTable = (ediOrderTableRes) => {
  
  // EDIType Codes
  const ediTypes = {
    "00":"ORIGINAL",
    "01":"CANCELLATION",
    "04":"CHANGE",
    "05":"REPLACE"
  }

  const orderTypeInitial = {
    INBOUND: 'I',
    OUTBOUND: 'O'
  }

  try {
    let ediTableData = ediOrderTableRes?.map((item,index) => {
      const processedTypeText=item?.status==="R"?"REJECT":item?.status==="A"?"ACCEPTED":item?.status==="U"?"UNASSIGNED":item?.status==="K"?"ACKNOWLEDGE":item?.status==="P"?"PENDING":""
      return {
        id: item?.id ||index,
        controlNumber: item?.controlNumber || '',
        customer:item?.customer ||"",
        customerRef:item?.customerIsa ||"",
        changingType: item?.ediTypeDesc || "",
        receivedDateTime:item?.receivedDateTime?convertDatetime(item?.receivedDateTime):""||"",
        expiresDateTime:item?.respondBy?convertDatetime(item?.respondBy):""||"",
        processedDateTime:item?.processedDateTime?convertDatetime(item?.processedDateTime):""||"",
        status:item?.status||"",
        processedType:processedTypeText||"",
        pickupDisp:item?.pickupDisp || "",
        orderType: orderTypeInitial[item?.orderType] || "NA",
      }
    })
    return  ediTableData;

  } catch (e) {
    console.log('Error occurred while mapping', e)
    return false;
  }
}


const orderTypeOptions = [
  {
    id: 1,
    value: "INBOUND",
    value2: "DOMINB"
  },
  {
    id: 2,
    value: "OUTBOUND",
    value2: "DOMOUT"
  },
  {
    id: 3,
    value: "IMPORT",
    value2: ""
  },
  {
    id: 4,
    value: "EXPORT",
    value2: ""
  },
];

const getDate = (dateVal) => {
  if (dateVal === 0 || !dateVal)
    return ""
  let date = dateVal.toString().substring(1)
  date = `20${date.substring(0, 2)}-${date.substring(2, 4)}-${date.substring(4)}`
  return date;
}



 
const getTime = (timeVal) => {
  if (!timeVal || timeVal === 0)
    return ""
  if (timeVal.toString().length === 3)
    timeVal = `0${timeVal.toString().substring(0, 1)}:${timeVal.toString().substring(1)}`
  else
    timeVal = `${timeVal.toString().substring(0, 2)}:${timeVal.toString().substring(2)}`
  return timeVal;
}

export const getDateDMY = (dateVal) => {
  if (dateVal === 0 || !dateVal)
    return ""
  if (moment(dateVal, 'YYYY-MM-DD', true).isValid()) {
    return dateVal;
  } else {
    let date = dateVal.toString().substring(1)
    date = `20${date.substring(0, 2)}-${date.substring(2, 4)}-${date.substring(4)}`
    return date;
  }
}

export const getRCDepartment = (glCode) => {
  const glCodeArr = [2026000, 2035000, 2175000, 2177000];
  if (glCodeArr.includes(glCode)) {
    return 0;
  }
  return 310;
}

export const recAgainstLineNumber = (obj) => {
  if (typeof obj?.lineNumber !== 'undefined' && obj?.lineNumber !== 0) {
    return obj.lineNumber;
  }
  return 0;
}
export const ratesChargesObjsForServer = (obj) => {
  const res = {
    lineNumber: obj.lineNumber,
    description: obj.description || "",
    notes: obj.notes || "",
    glDescription: obj.glDescription || "",
    glCode: obj.glCode || "",
    originName: obj.originName || "",
    originCity: obj.originCity || "",
    originPrefix: obj.originPrefix || "",
    destinationCity: obj.destinationCity || "",
    destinationStatePrefix: obj.destinationStatePrefix || "",
    accountLine: obj.accountLine || "",
    unitRate: obj.unitRate || "",
    units: obj.units || "",
    amount: obj.amount || "",
    transmitted: obj.transmitted || "",
    truckNumber: obj.truckNumber || "",
    parentLineNumber: obj.parentLineNumber || "",
    splitLineNumber: obj.splitLineNumber || "",
    loadType: obj.loadType || "",
    subPer: obj.subPer || "",
    payRec: obj.payRec || "",
    payType: obj.payType || "",
    payableRecievable: obj.payableReceivable || "",
    processedDate: (obj.processedDate !== '') ? getDateDMY(obj.processedDate) : '',
    department: getRCDepartment(obj.glCode),
    recAgainstSplitLine: obj.recAgainstSplitLine || 0,
    splitType: obj.splitType || '',
    commonSplitLine: obj.commonSplitLine || 0,
  }
  return res
}

function trimText(data, length) {
  if (data === undefined || data === null) {
    return ''; // Return an empty string or handle the error as needed
}
  // Convert the data to a string (in case numbers are passed)
  const strData = data.toString();
  
  // Trim the string to the specified length
  return strData.substring(0, length);
}
export const mapOrderToServer = (item) => {
  try {
    let billingControl = {
      orderBy: {
        shipperAgentCode: item.billingControl.orderBy.orderBy || "",
        shipperAgent: item.billingControl.orderBy.name || "",
        shipperOrAgentLoc: item.billingControl.orderBy.location || "",
        shipperAgentPhone: item.billingControl.orderBy.phone || "",
        shipperAgentFax: item.billingControl.orderBy.fax || "",
        email: item.billingControl.orderBy.email || "",
        contact: item.billingControl.orderBy.contact || "",
        customerNumber: item.billingControl.orderBy.billTo || "",
        billingReference: item.billingControl.orderBy.reference || "",
        status: item.billingControl.orderBy.billToStatus || "",
        billingDate:"",
        dispositionCOrderByContactSeq:item.billingControl.orderBy.orderContactSeq|| 0
      },
      equipmentDetails: {
        unitNumber: trimText(item.billingControl.equipmentDetails.unitNum,13) || "",
        chassisNumber: item.billingControl.equipmentDetails.chassis || "",
        equipSize: trimText(item.billingControl.equipmentDetails.equipmentSize,8)|| "",
        realeaseNumber: item.billingControl.equipmentDetails.release || "",
        ssl: item.billingControl.equipmentDetails.ssl || "",
        billingBooking: item.billingControl.equipmentDetails.booking || "",
        voyageNumber: item.billingControl.equipmentDetails.voyage || "",
        vessel: item.billingControl.equipmentDetails.vessel || "",
        dispositionCDestinationPort: item.billingControl.equipmentDetails.destinationPort || "",
        equipmentType: item.billingControl.equipmentDetails.equipmentType || '',
        secondaryUnit: item.billingControl.equipmentDetails.secondaryUnit || '',
        secondaryChassis: item.billingControl.equipmentDetails.secondaryChassis || '',
        chassisProvider: item.billingControl.equipmentDetails.chassisProvider || '',
        chassisRelease: item.billingControl.equipmentDetails.chassisRelease || '',
        hazMat: item.billingControl.equipmentDetails.hazMat || 'N',
        tanker: item.billingControl.equipmentDetails.tanker || 'N',
        overWeight: item.billingControl.equipmentDetails.overWeight || 'N',
        reefer: item.billingControl.equipmentDetails.reefer || 'N',
        residential: item.billingControl.equipmentDetails.residential || 'N',
      },
    
      pickup: {
        poruPoint: item.billingControl.pickup.pickupPoint || "",
        poruDate:"",
        poruTime:"",
        disposition: item.billingControl.pickup.disposition || "",
        returnDate:"",
        returnTime:"",
        dispositionCChassisPickupPoint: item.billingControl.pickup.chassisPickup || "",
        chassisPickupDate: '',
        chassisPickupTime: '',
        dispositionCChassisDisposition: item.billingControl.pickup.chassisDisposition || "",
        chassisReturnDate:'',
        chassisReturnTime:'',
        notifyDate:"",
        notifyTime:"",
        cuttOffDate:"",
        cutOffTime:"",
        pickDate:"",
        etaDate:"",
        etaTime:""
      },
      // Shipper value or consignee value - defaul to shipper
      shipper: {
        shipperOrConsShipper: item.billingControl.shipper.shipper || "",
        shipper: item.billingControl.shipper.name || "",
        //as per akash instruction sending ""
        billingShipperPO: "",
        shipperAddress: item.billingControl.shipper.address || "",
        shipperCity: item.billingControl.shipper.city || "",
        shipperState: item.billingControl.shipper.state || "",
        shipperZip: item.billingControl.shipper.zip || "",
        shipperPhone: item.billingControl.shipper.phone || "",
        shipperFax: item.billingControl.shipper.fax || "",
      },
      customerNotes: {
        internalNotes: item.billingControl.customerNotes.internalNotes || "",
      },
      driverNotes: {
        driverNotes: item.billingControl.driverNotes.driverNotes || "",
        driverNotes2: item.billingControl.driverNotes.driverNotes2 || "",
      },
      masterDetails: {
        billOfLading: item.billingControl.masterLoadDetails.billOfLading || "",
        purchaseOrder: item.billingControl.masterLoadDetails.purchaseOrder || "",
      },
      orderType: item.billingControl.orderType || "",
    }


    let stops = item.stops.map(stop => {
      const splitNote =item?.internalNote?.match(/.{1,25}/g)
      return {
        lineNumber: stop.stop || "",
        newLineNumber:stop.stop ||"",
        code: trimText(stop.consignee,7) || "",
        pickupNumber: stop.pickupNumber || "",
        name: trimText(stop.name,25) || "",
        address: trimText(stop.address,25) || "",
        city: trimText(stop.city,25) || "",
        state: trimText(stop.state,2) || "",
        zip: trimText(stop.zip,10) || "",
        phone:trimText(stop.Phone,13) || "",
        fax: trimText(stop.fax,13) || "",
        contact: stop.contact || "",
        contactEmail: stop.contactEmail || "",
        poPU: stop.contactEmail || "",
        shipperBillOfLadding: stop.shipperBillOfLadding || "",
        apptDate: stop.appointmentDate || "",
        apptTime: stop.appointmentTime || "",
        pieces: stop.totalPieces || "",
        weight: trimText(stop.totalWeight,10) || "",
        commodity: trimText(stop.commodity,50) || "",
        doorSeal: stop.doorSeal || "",
        arriveDate: stop.arriveDate || "",
        arriveTime: stop.arriveTime || "",
        spotDate: stop.spotDate || "",
        spotTime: stop.spotTime || "",
        unloadDate: stop.unloadDate || "",
        unloadTime: stop.unloadTime || "",
        departDate: stop.departDate || "",
        departTime: stop.departTime || "",
        availDate: stop.availDate || "",
        availTime: stop.availTime || "",
        etaDate: stop.etaDate || "",
        etaTime: stop.etaTime || "",
        arriveTruck: stop.arriveTruck || "",
        arriveDriver: stop.arriveDriver || "",
        departTruck: stop.departTruck || "",
        departDriver: stop.departDriver || "",
        arriveTruckStatus: stop.arriveTruckStatus || "",
        departTruckStatus: stop.departTruckStatus || "",
        arriveDriverStatus: stop.arriveDriverStatus || "",
        // departDriverStatus: stop.departDriverStatus || "",
        tempPulp: stop.tempPulp || "",
        internalNotes: stop.internalNotes || "",
        driverNotes: stop.driverNotes || "",
        driverNotes2: stop.driverNotes2 || "",
        // driverNotes:splitNote && splitNote[0] ? splitNote[0] : "",
        // driverNotes2:splitNote && splitNote[1] ? splitNote[1]: "",
      }
    })

    // Order type is OUTBOUND OR EXPORT
    if (billingControl.orderType === "OUTBOUND" || billingControl.orderType === "EXPORT") {
      // Shipper becomes the first Stop

      billingControl.shipper = {
        shipperOrConsShipper: stops[0]?.code || "",
        shipper: stops[0]?.name || "",
        billingShipperPO: "",
        shipperAddress: stops[0]?.address || "",
        shipperCity: stops[0]?.city || "",
        shipperState: stops[0]?.state || "",
        shipperZip: stops[0]?.zip || "",
        shipperPhone: stops[0]?.phone || "",
        shipperFax: stops[0]?.fax || "",
      }
      // Consignee Becomes the Shipper
      billingControl.consignee = {
        shipperOrConsShipper: item.billingControl.shipper.shipper || "",
        consignee: item.billingControl.shipper.name || "",
        billingConsigneePO: item.billingControl.shipper.pickupNumber || "",
        consigneeAddress: item.billingControl.shipper.address || "",
        consigneeCity: item.billingControl.shipper.city || "",
        consigneeState: item.billingControl.shipper.state || "",
        consigneeZip: item.billingControl.shipper.zip || "",
        consigneePhone: item.billingControl.shipper.phone || "",
        consigneeFax: item.billingControl.shipper.fax || "",
      }
      billingControl.orderType = billingControl.orderType === "OUTBOUND" ? "DOMOUT" : "EXPORT"
    }
    else if (billingControl.orderType === "INBOUND" || billingControl.orderType === "IMPORT") {
      // Consignee is First Stop
      billingControl.consignee = {
        shipperOrConsShipper: stops[0]?.code || "",
        consignee: stops[0]?.name || "",
        billingConsigneePO: stops[0]?.pickupNumber || "",
        consigneeAddress: stops[0]?.address || "",
        consigneeCity: stops[0]?.city || "",
        consigneeState: stops[0]?.state || "",
        consigneeZip: stops[0]?.zip || "",
        consigneePhone: stops[0]?.phone || "",
        consigneeFax: stops[0]?.fax || "",
      }

      // Shipper is Shipper Already Assigned by Default
      billingControl.orderType = billingControl.orderType === "INBOUND" ? "DOMINB" : "IMPORT"
    }


    let ratesChargesarray = [];
    console.log('rates charge saving', item.ratecharges);
    item.ratecharges.map((item, indx) => {
      let rowIndex = Number(indx) + 1;
      if (item.payable.payableReceivable === 'P') {
        const { subItems: _, splitItems: __, ...newObj } = item?.payable;

        ratesChargesarray.push(ratesChargesObjsForServer(newObj));

        if (Object.keys(item?.payable).length !== 0) {
          item?.payable?.subItems?.length > 0 && item?.payable?.subItems?.map(subitem => {
            //if (subitem?.splitItems?.length === 0) {
            ratesChargesarray.push(ratesChargesObjsForServer(subitem));
            //}
            subitem?.splitItems?.map(splititem => {
              ratesChargesarray.push(ratesChargesObjsForServer({ ...splititem, recAgainstSplitLine: recAgainstLineNumber(item.receivable?.subItems?.[0]), splitType: 'C', commonSplitLine: rowIndex }));
            })
          })

          item?.payable?.splitItems?.map(splitItems => {
            ratesChargesarray.push(ratesChargesObjsForServer({ ...splitItems, recAgainstSplitLine: recAgainstLineNumber(item.receivable), splitType: 'P', commonSplitLine: rowIndex }));
          })

        }
      }

      if (typeof item.payable.payableReceivable === 'undefined' && item.payable?.splitItems?.length > 0) {

        item?.payable?.splitItems?.map(splitItems => {
          ratesChargesarray.push(ratesChargesObjsForServer({ ...splitItems, recAgainstSplitLine: recAgainstLineNumber(item.receivable), splitType: 'P', commonSplitLine: rowIndex }));
        })

        if (item?.payable?.subItems?.length > 0 && item?.payable?.subItems?.[0]?.splitItems?.length > 0) {
          item.payable?.subItems?.[0]?.splitItems?.map(splititem => {
            ratesChargesarray.push(ratesChargesObjsForServer({ ...splititem, recAgainstSplitLine: recAgainstLineNumber(item.receivable?.subItems?.[0]), splitType: 'C', commonSplitLine: rowIndex }));
          })
        }

      }

      if (item.receivable.payableReceivable === 'R') {

        const { subItems: _, splitItems: __, ...newObj } = item?.receivable;
        ratesChargesarray.push(ratesChargesObjsForServer(newObj));

        if (Object.keys(item?.receivable).length !== 0) {

          item?.receivable?.subItems?.length > 0 && item?.receivable?.subItems?.map(subitem => {
            ratesChargesarray.push(ratesChargesObjsForServer(subitem));
          })

        }
      }
    });

    const ratesCharges = ratesChargesarray.sort((a, b) => a.lineNumber - b.lineNumber).filter(item => item.lineNumber !== '');
    // return { ...billingControl, stops }
    return { ...billingControl, stops, ratesCharges }

  }
  catch (e) {
    console.log('Error occurred while mapping', e)
    return false;
  }
}





export const mapEdiOrderToClient = (item,isEdiData=true) => {
const getConditionalValuesFromEDIJson = (items) => {
  // Initialize an object to store the results
  let result = {
    UnitReleaseNumber: "",
    LINEHAUL: "",
    FUEL: "",
    ACCESSORIAL: ""
  };

  // Loop through the items to find the required identifiers
  items?.forEach(item => {
    if (item.Qualifier === "RE" && item.QualDesc === "RELEASE NUMBER") {
      result.UnitReleaseNumber = item.Identifier;
    } else if (item.Qualifier === "SP") {
      const identifierParts = item?.Identifier?.split("/");
      
      // Ensure identifierParts has both parts
      if (identifierParts.length === 2) {
        if (identifierParts[0] === "LHL") {
          result.LINEHAUL = identifierParts[1] || result.LINEHAUL;
        } else if (identifierParts[0] === "FUE") {
          result.FUEL = identifierParts[1] || result.FUEL;
        } else if (identifierParts[0] === "ACC") {
          result.ACCESSORIAL = identifierParts[1] || result.ACCESSORIAL;
        }
      }
    }else if (item.Qualifier === "22") {
      if (item.QualDesc?.toLowerCase() === "line haul") {
        result.LINEHAUL = item.Identifier || result.LINEHAUL;
      } else if (item.QualDesc?.toLowerCase() === "fuel surcharge") {
        result.FUEL = item.Identifier || result.FUEL;
      }
    }
  });

  return result;
};

const ConditionalValuesFromEDIJson = isEdiData?getConditionalValuesFromEDIJson(item?.ediJson[0]?.References):null;
const unitReleaseNumber = (item?.releaseNumber && item?.releaseNumber !== '') ? item.releaseNumber:ConditionalValuesFromEDIJson?.UnitReleaseNumber;
  // const checkIsBillingDateLocked = item?.rates?.linehaul?.some(row => checkingBillingDateLocked(row));
  let billingControl = {
    orderBy: {
      orderBy: item?.shipperAgentCode || "",
      name: item?.shipperAgent || "",
      location: item?.shipperOrAgentLoc || "",
      phone: "",
      fax: item?.shipperAgentFax || "",
      email: item?.email || "",
      contact: item?.contact || "",
      billTo: item?.customerNumber || "",
      reference: item?.billingReference || "",
      billToStatus: item?.status || "",
      billingDate: getDate(item?.billingDate) || "",
      // isBillingDateLocked: checkIsBillingDateLocked || false,
      orderContactSeq: item?.dispositionCOrderByContactSeq || 0,
      isBillingDateLocked:false,
    },
    equipmentDetails: {
      unitNum: item?.unitNumber || "",
      chassis: item?.chassisNumber || "",
      equipmentSize: item?.equipSize || "",
      // release: item?.releaseNumber || "",
      release: unitReleaseNumber?.length <= 10 ? unitReleaseNumber : "",
      ssl: item?.ssl || "",//not found in api
      booking: item?.billingBooking || "",
      voyage: item?.voyageNumber || "",
      vessel: item?.vessel || "",
      destinationPort: item?.dispositionCDestinationPort || "",
      equipmentType: item?.equipmentType || '',//not found in api
      secondaryUnit: item?.secondaryUnit || '',
      secondaryChassis: item?.secondaryChassis || '',//not found in api
      chassisProvider: item?.chassisProvider || '',//not found in api
      chassisRelease: item?.chassisRelease || '',//not found in api
      hazMat: item?.hazMat || 'N',
      tanker: item?.tanker || 'N',//not found in api
      overWeight: item?.overWeight || 'N',//not found in api
      reefer: item?.reefer || 'N',//not found in api
      residential: item?.residential || 'N',//not found in api
    },

    customerNotes: {
      internalNotes: item?.internalNotes || ""//not found in api
    },

    // Shipper value or consignee value - defaul to shipper
    shipper: {
      shipper: item?.shipperOrConsShipper || "",
      name: item?.shipper || "",
      pickupNumber: item?.billingShipperPO || "",
      address: item?.shipperAddress || "",
      city: item?.shipperCity || "",
      state: item?.shipperState || "",
      zip: item?.shipperZip || "",
      phone: item?.shipperPhone || "",
      fax: item?.shipperFax || "",
    },
    pickup: {
      pickupPoint:item?.poruPoint||"",
      pickupPointDefault:item?.poruPoint||"",
      pickupDate: getDate(item?.poruDate) || "",
      pickupTime: getTime(item?.poruTime) || "",
      disposition: item?.disposition || "",
      dispositionDefault:item?.disposition||"",
      returnDate: getDate(item?.returnDate) || "",
      returnTime: getTime(item?.returnTime) || "",
      chassisPickup: item?.dispositionCChassisPickupPoint || "",
      chassisPickupDate: '',
      chassisPickupTime: '',
      chassisDisposition: item?.dispositionCChassisDisposition || "",
      chassisReturnDate: '',
      chassisReturnTime: '',
      notifyDate: getDate(item?.notifyDate) || "",
      notifyTime: getTime(item?.notifyTime) || "",
      lastFreeDay: getDate(item?.cuttOffDate) || "",
      lastFreeTime: getTime(item?.cutOffTime) || "",
      lastPerDiemDays: getDate(item?.pickDate) || "",
      etaDate: getDate(item?.etaDate) || "",
      etaTime: getTime(item?.etaTime) || ""
    },
    driverNotes: {
      driverNotes: item?.remarks1,
      driverNotes2: item?.remarks2,
    },
    masterLoadDetails: {
      billOfLading: item?.billOfLading || "",
      purchaseOrder: item?.purchaseOrder || "",
    },
    orderType: orderTypeOptions?.filter(option => item?.orderType === option.value || item?.orderType === option.value2)[0]?.value,
  }

  // Order type is OUTBOUND OR EXPORT
  if (billingControl.orderType === "OUTBOUND" || billingControl.orderType === "EXPORT") {
    // Shipper becomes consignee in above object
    billingControl.shipper = {
      shipper: item?.shipperOrConsignee,
      name: item?.consignee,
      pickupNumber: item?.billingConsigneePO,
      address: item?.consigneeAddress,
      city: item?.consigneeCity,
      state: item?.consigneeState,
      zip: item?.consigneeZip,
      phone: item?.consigneePhone,
      fax: item?.consigneeFax,
    }
  }

    let ediAdditionalNotes = item?.ediAdditionalNotes;
  // let stops = (item?.stops) ? item?.stops?.map(stop => {
    let stops = (item?.stops) ? item.stops.map((stop, index) => {
      // Check if item.disposition exists and this is the last stop
      // if (isEdiData &&item?.disposition && index === item.stops.length - 1) {
      //   if (index===0){
      //     return null
      //   }else{
      //     return null
      //   }
      // }

      /**
       * Modified: 15 Octover, 2024
       * As per recent discussion with Eagle team, It doesnt matter whether disposition value is set or not just hide the last stop because its a part of disposition field
       */
      if (isEdiData && index === item.stops.length - 1 && ["OUTBOUND","EXPORT"].includes(billingControl.orderType)) { // item?.disposition && 
        return null; // Skip the last stop
      }
    
      // Check if item.pickup exists and this is the first stop
      /**
       * Modified: 15 Octover, 2024
       * By default stop zero is a part of pickup
       */
      if ((isEdiData && stop.lineNumber == 1 && ["INBOUND","IMPORT"].includes(billingControl.orderType)) || stop.lineNumber === 0 ) { // item?.poruPoint && 
        return null; // Skip the first stop
      }
      let customizedLineNumber = stop?.lineNumber ? stop.lineNumber - ((["INBOUND","IMPORT"].includes(billingControl.orderType)) ? 1 : 0) : 1
      ediAdditionalNotes += (stop?.requestedDateTime)?`Stop ${customizedLineNumber} requested datetime: ${stop?.requestedDateTime} \n`:'';
      return {
        // stop: stop?.lineNumber||1,
        // newStop:stop?.lineNumber||1,
        stop: customizedLineNumber,
        newStop: customizedLineNumber,
        consignee: stop?.code?stop?.code:stop?.name?"MANUAL":"",
        pickupNumber: stop?.pickupNumber||"",
        name: stop?.name||"",
        address: stop?.address||"",
        city: stop?.city||"",
        state: stop?.state||"",
        zip: stop?.zip||"",
        phone: stop?.contactPhone||"",//stop?.phone,
        fax: stop?.fax||"",
        shipperBillOfLadding: stop?.shipperBillOfLadding||"",
        appointmentDate: getDate(stop?.apptDate)||"",
        appointmentTime: stop?.apptTime||"", //getTime(stop.apptTime),
        totalPieces: stop?.pieces||"",
        totalWeight: stop?.weight||"",
        commodity: stop?.commodity||"",
        // doorSeal: (["OUTBOUND","EXPORT"].includes(billingControl.orderType))?(item.stops?.length - 2 === index):(item.stops?.length - 1 === index) ? stop?.doorSeal || "" : "",
        doorSeal: isEdiData?(["OUTBOUND","EXPORT"].includes(billingControl.orderType))?item.stops[item.stops?.length - 2]?.doorSeal=== "NA"?"":item.stops[item.stops?.length - 2]?.doorSeal ||"": item.stops[item.stops?.length - 1].doorSeal==="NA"?"":item.stops[item.stops?.length - 1].doorSeal ||"":stop?.doorSeal,
        etaDate: getDate(stop?.etaDate)||"",
        etaTime: getTime(stop?.etaTime)||"",
        arriveDate: getDate(stop?.arriveDate)||"",
        arriveTime: getTime(stop?.arriveTime)||"",
        spotDate: getDate(stop?.spotDate)||"",
        spotTime: getTime(stop?.spotTime)||"",
        unloadDate: getDate(stop?.unloadDate)||"",
        unloadTime: getTime(stop?.unloadTime)||"",
        departDate: getDate(stop?.departDate)||"",
        departTime: getTime(stop?.departTime)||"",
        availDate: getDate(stop?.availDate)||"",
        availTime: getTime(stop?.availTime)||"",
        arriveTruck: stop?.arriveTruck||"",
        arriveDriver: stop?.arriveDriver||"",
        departTruck: stop?.departTruck||"",
        departDriver: stop?.departDriver||"",
        arriveTruckStatus: stop?.arriveTruckStatus||"",
        departTruckStatus: stop?.departTruckStatus||"",
        arriveDriverStatus: stop?.arriveDriverStatus||"",
        departDriverStatus: stop?.departDriverStatus||"",
        driverNotes: stop?.driverNotes||"",
        driverNotes2: stop?.driverNotes2||"",
        isServerRecord: true,
        internalNotes: stop?.internalNotes||"",
        contactName: stop?.contactName||"",
        contactPhone: stop?.contactPhone||"",
        contactEmail: stop?.contactEmail||"",

        arriveTenderStatus: stop?.arriveTenderStatus||"",
        departTenderStatus: stop?.departTenderStatus||""
      }
    // }
  }).filter(stop => stop !== null) : []
  // let orderNotes = (item?.orderNotes) ? item?.orderNotes?.map(note=> {
  //   return {
  //     userId: note?.userId || "EDI",
  //     note: note?.note || "",
  //     time: note?.time || "",
  //   }
  // }) : []
  let orderNotes = (item?.orderNotes) ? item?.orderNotes?.map(note => {
    return {
      userId: note?.userId || "EDI",
      note: note?.note || "",
      time: note?.time || "",
    }
  }) : [];
  let receivedDateTime=item.receivedDate ||""

  if(ediAdditionalNotes !== ''){
    orderNotes.push({
      userId: "EDI",
      note: ediAdditionalNotes,
      time: ""
      // time: moment().format('YYYY-MM-DD HH:mm:ss'),
    });
  }
  // Check if item?.ediJson?.BillTo?.Note is already in orderNotes
  let billToNote = item?.note; // + '<br/>' + requestedDateTime;
  if (billToNote && !orderNotes.some(note => note.note === billToNote)) {
    orderNotes.push({
      userId: "EDI",
      note: billToNote,
      time: ""
      // time: moment().format('YYYY-MM-DD HH:mm:ss'),
    });
  }
  if(isEdiData && ediAdditionalNotes && !orderNotes.some(note => note.note === ediAdditionalNotes)) {
    orderNotes.push({
      userId: "EDI",
      note: ediAdditionalNotes,
      time: ""
      // time: moment().format('YYYY-MM-DD HH:mm:ss'),
    });
  }
  if (unitReleaseNumber?.length>10) {
    orderNotes.push({
      userId: "EDI",
      note: `Unit Release:${unitReleaseNumber}`,
      time: ""
      // time: moment().format('YYYY-MM-DD HH:mm:ss'),
    });
  }
  if(ConditionalValuesFromEDIJson?.LINEHAUL||ConditionalValuesFromEDIJson?.FUEL||ConditionalValuesFromEDIJson?.ACCESSORIAL){
    orderNotes.push({
      userId: "EDI",
      note: `RATES & CHARGES : LINEHAUL:${ConditionalValuesFromEDIJson.LINEHAUL},FUEL:${ConditionalValuesFromEDIJson.FUEL},ACCESSORIAL:${ConditionalValuesFromEDIJson?.ACCESSORIAL}`,
      time: ""
    });
  }
  let linehaul = (item?.rates) ? item?.rates?.linehaul : [];
  let accessorial = (item?.rates) ? item?.rates?.accessorial : [];
  let selectedStop;
  let isMatchingPickup
  let isMatchingDisposition
  if (billingControl.orderType === "INBOUND" || billingControl.orderType === "IMPORT") {
    // selectedStop = stops?.[0]; // First stop for INBOUND or IMPORT
    selectedStop=item?.stops?.[0]
  } else if (billingControl.orderType === "OUTBOUND" || billingControl.orderType === "EXPORT") {
    // selectedStop = stops?.[stops.length - 1]; // Last stop for OUTBOUND or EXPORT
    selectedStop=item?.stops?.[item?.stops?.length-1]
  }
//   if (selectedStop) {
//     // Matching Pickup
//     isMatchingPickup = selectedStop.address === item?.poruPoint &&
//                              selectedStop.city === item?.poruPoint &&
//                              selectedStop.state === item?.poruPoint;

//     // Matching Disposition
//      isMatchingDisposition = selectedStop.address === item?.disposition &&
//                                   selectedStop.city === item?.disposition &&
//                                   selectedStop.state === item?.disposition;

// }

billingControl={...billingControl,selectedStop}
orderNotes=splitOrderNotes(orderNotes)

  return { billingControl, stops, linehaul, accessorial,orderNotes, locationNumber: item.locationNumber,selectedStop,receivedDateTime}
}


export const mapAcceptRejectEdiOrderToServer = (selectedRowNotes,type,isUpdateEDI,reason,note,controlNo, reference) => {
  let now = new Date();
  // Format date as YYYY-MM-DD
  let date = now.toISOString().split('T')[0];
  // Format time as HH:MM:SS
  let time = now.toTimeString().split(' ')[0];

  return{
    TransactionId: 990, //selectedRowNotes?.TransactionId ||"",
    InternalTrackingNum: selectedRowNotes?.InternalTrackingNum ||"",
    Customer: selectedRowNotes?.Customer ||"",
    CustomerISA: selectedRowNotes?.CustomerISA ||"",
    SCAC: selectedRowNotes?.SCAC ||"",
    Response: type||"", 
    ShipmentId: selectedRowNotes?.ShipmentId ||"",
    ProNumber: controlNo||"", 
    TxnNumber: ""||"", 
    Timestamp: `${date}T${time}`, 
    TimeZone: "LT", //""||"",
    Note:  note||"",
    RejectionReason:reason||"",
    References: reference||"",
    sendEdi:isUpdateEDI||false,
    ediInfo:{
      shipmentId: selectedRowNotes?.ShipmentId || "",
      receivedDate: selectedRowNotes?.ReceivedDate || "",
    }
  }
};
export const mapRampEdiOrderToServer = (selectedRowNotes,rampName,user,currentOrder) => {
  return{
    company:1,
    partnerId:selectedRowNotes?.Customer ||"",
    locationNumber:selectedRowNotes?.LocationNumber||"",
    city:currentOrder?.selectedStop?.city||"",
    state:currentOrder?.selectedStop?.state||"",
    billingRamp:rampName||"",
    lastUser:user||"",
    lastDate: new Date().toISOString(),
  }
}


export const checkNonMatchingFields=(nonMatchingFields, targetField)=> {
  return nonMatchingFields?.some(fieldInfo => fieldInfo?.field === targetField);
}
