import moment from 'moment';
import {formatDate} from "../../../utils/formatDate"
export const getDateDMY = (dateVal) => {
  if (dateVal === 0 || !dateVal)
    return ""
  if (moment(dateVal, 'YYYY-MM-DD', true).isValid()) {
    return dateVal;
  } else {
    let date = dateVal.toString().substring(1)
    date = `20${date.substring(0, 2)}-${date.substring(2, 4)}-${date.substring(4)}`
    return date;
  }
}
export const getRCDepartment = (glCode) => {
  const glCodeArr = [2026000, 2035000, 2175000, 2177000];
  if (glCodeArr.includes(glCode)) {
    return 0;
  }
  return 310;
}
export const recAgainstLineNumber = (obj) => {
  if (typeof obj?.lineNumber !== 'undefined' && obj?.lineNumber !== 0) {
    return obj.lineNumber;
  }
  return 0;
}
const orderTypeOptions = [
  {
    id: 1,
    value: "INBOUND",
    value2: "DOMINB"
  },
  {
    id: 2,
    value: "OUTBOUND",
    value2: "DOMOUT"
  },
  {
    id: 3,
    value: "IMPORT",
    value2: ""
  },
  {
    id: 4,
    value: "EXPORT",
    value2: ""
  },
];
const getDate = (dateVal) => {
  if (dateVal === 0 || !dateVal)
    return ""
  let date = dateVal.toString().substring(1)
  date = `20${date.substring(0, 2)}-${date.substring(2, 4)}-${date.substring(4)}`
  return date;
}
const getTime = (timeVal) => {
  if (!timeVal || timeVal === 0)
    return ""
  if (timeVal.toString().length === 3)
    timeVal = `0${timeVal.toString().substring(0, 1)}:${timeVal.toString().substring(1)}`
  else
    timeVal = `${timeVal.toString().substring(0, 2)}:${timeVal.toString().substring(2)}`
  return timeVal;
}
// new
const checkingBillingDateLocked = (item) => {
  return item?.receivable?.isBillingDateLocked ||
    (item?.receivable?.subItems?.length > 0 && item?.receivable?.subItems[0]?.isBillingDateLocked);
}
export const mapOrderToClient = (item) => {
  const checkIsBillingDateLocked = item?.rates?.linehaul?.some(row => checkingBillingDateLocked(row));

  let billingControl = {
    orderBy: {
      orderBy: item?.shipperAgentCode || "",
      name: item?.shipperAgent || "",
      location: item?.shipperOrAgentLoc || "",
      phone: "",
      fax: item?.shipperAgentFax || "",
      email: "",
      contact: "",
      billTo: item?.customerNumber || "",
      reference: item?.billingReference || "",
      billToStatus: item?.status || "",
      billingDate: getDate(item?.billingDate) || "",
      isBillingDateLocked: checkIsBillingDateLocked || false,
      orderContactSeq: item?.dispositionCOrderByContactSeq || ''
    },
    equipmentDetails: {
      unitNum: item?.unitNumber || "",
      chassis: item?.chassisNumber || "",
      equipmentSize: item?.equipSize || "",
      release: item?.releaseNumber || "",
      ssl: item?.ssl || "",
      booking: item?.billingBooking || "",
      voyage: item?.voyageNumber || "",
      vessel: item?.vessel || "",
      destinationPort: item?.dispositionCDestinationPort || "",
      equipmentType: item?.equipmentType || '',
      secondaryUnit: item?.secondaryUnit || '',
      secondaryChassis: item?.secondaryChassis || '',
      chassisProvider: item?.chassisProvider || '',
      chassisRelease: item?.chassisRelease || '',
      hazMat: item?.hazMat || 'N',
      tanker: item?.tanker || 'N',
      overWeight: item?.overWeight || 'N',
      reefer: item?.reefer || 'N',
      residential: item?.residential || 'N',
    },

    customerNotes: {
      internalNotes: item?.internalNotes || ""
    },

    // Shipper value or consignee value - defaul to shipper
    shipper: {
      shipper: item?.shipperOrConsShipper || "",
      name: item?.shipper || "",
      pickupNumber: item?.billingShipperPO || "",
      address: item?.shipperAddress || "",
      city: item?.shipperCity || "",
      state: item?.shipperState || "",
      zip: item?.shipperZip || "",
      phone: item?.shipperPhone || "",
      fax: item?.shipperFax || "",
    },
    pickup: {
      pickupPoint: item?.poruPoint || "",
      pickupDate: getDate(item?.poruDate) || "",
      pickupTime: getTime(item?.poruTime) || "",
      disposition: item?.disposition || "",
      returnDate: getDate(item?.returnDate) || "",
      returnTime: getTime(item?.returnTime) || "",
      chassisPickup: item?.dispositionCChassisPickupPoint || "",
      chassisPickupDate: '',
      chassisPickupTime: '',
      chassisDisposition: item?.dispositionCChassisDisposition || "",
      chassisReturnDate: '',
      chassisReturnTime: '',
      notifyDate: getDate(item?.notifyDate) || "",
      notifyTime: getTime(item?.notifyTime) || "",
      lastFreeDay: getDate(item?.cuttOffDate) || "",
      lastFreeTime: getTime(item?.cutOffTime) || "",
      lastPerDiemDays: getDate(item?.pickDate) || "",
      etaDate: getDate(item?.etaDate) || "",
      etaTime: getTime(item?.etaTime) || ""
    },
    driverNotes: {
      driverNotes: item?.remarks1,
      driverNotes2: item?.remarks2,
    },
    masterLoadDetails: {
      billOfLading: item?.billOfLading || "",
      purchaseOrder: item?.purchaseOrder || "",
    },

    orderType: orderTypeOptions?.filter(option => item?.orderType === option.value || item?.orderType === option.value2)[0]?.value,
  }

  // Order type is OUTBOUND OR EXPORT
  if (billingControl.orderType === "OUTBOUND" || billingControl.orderType === "EXPORT") {
    // Shipper becomes consignee in above object
    billingControl.shipper = {
      shipper: item?.shipperOrConsignee,
      name: item?.consignee,
      pickupNumber: item?.billingConsigneePO,
      address: item?.consigneeAddress,
      city: item?.consigneeCity,
      state: item?.consigneeState,
      zip: item?.consigneeZip,
      phone: item?.consigneePhone,
      fax: item?.consigneeFax,
    }
  }

  let stops = (item?.stops) ? item?.stops?.map(stop => {
    if (stop?.lineNumber !== null) {
      return {
        stop: stop?.lineNumber,
        newStop: stop?.lineNumber,
        consignee: stop?.code,
        pickupNumber: stop?.pickupNumber,
        name: stop?.name,
        address: stop?.address,
        city: stop?.city,
        state: stop?.state,
        zip: stop?.zip,
        phone: stop?.contactPhone,//stop?.phone,
        fax: stop?.fax,
        shipperBillOfLadding: stop?.shipperBillOfLadding,
        appointmentDate: getDate(stop?.apptDate),
        appointmentTime: stop?.apptTime, //getTime(stop.apptTime),
        totalPieces: stop?.pieces,
        totalWeight: stop?.weight,
        commodity: stop?.commodity,
        doorSeal: stop?.doorSeal,
        etaDate: getDate(stop?.etaDate),
        etaTime: getTime(stop?.etaTime),
        arriveDate: getDate(stop?.arriveDate),
        arriveTime: getTime(stop?.arriveTime),
        spotDate: getDate(stop?.spotDate),
        spotTime: getTime(stop?.spotTime),
        unloadDate: getDate(stop?.unloadDate),
        unloadTime: getTime(stop?.unloadTime),
        departDate: getDate(stop?.departDate),
        departTime: getTime(stop?.departTime),
        availDate: getDate(stop?.availDate),
        availTime: getTime(stop?.availTime),
        arriveTruck: stop?.arriveTruck,
        arriveDriver: stop?.arriveDriver,
        departTruck: stop?.departTruck,
        departDriver: stop?.departDriver,
        arriveTruckStatus: stop?.arriveTruckStatus,
        departTruckStatus: stop?.departTruckStatus,
        arriveDriverStatus: (stop?.arriveTruckStatus === 'C') ? 'C' : stop?.arriveTenderStatus,
        departDriverStatus: (stop?.departTruckStatus === 'C') ? 'C' : stop?.departTenderStatus,
        driverNotes: stop?.driverNotes,
        driverNotes2: stop?.driverNotes2,
        isServerRecord: true,
        internalNotes: stop?.internalNotes,
        contactName: stop?.contactName,
        contactPhone: stop?.contactPhone,
        contactEmail: stop?.contactEmail,

        arriveTenderStatus: stop?.arriveTenderStatus,
        departTenderStatus: stop?.departTenderStatus,
        defaultContact: stop?.defaultContact
      }
    }
  }) : []

  /**
   * 19-sep-2023
   */
  let linehaul = (item?.rates) ? item?.rates?.linehaul : [];
  let accessorial = (item?.rates) ? item?.rates?.accessorial : [];

  return { billingControl, stops, linehaul, accessorial }
}
export const maptoCompletedOrderTable = (data) => {
console.log("item-data",data)
  try {
    const filteredAndMappedData = data
      .filter(item => (item?._source.totalStops === item?._source?.["stops.lineNumber"] && item?._source?.status==="C"))
      .map((item, index) => ({
        id: index,
        controlNumber: item?._source?.controlNumber,
        orderType: item?._source?.orderType,
        returnDate: formatDate(item?._source?.returnDate),
        unitNumber: item?._source?.unitNumber,
        shipperAgentCode: item?._source?.shipperAgentCode,
        referenceNumber: item?._source?.shipperAgentCode,
        shipperOrConsShipper: item?._source?.shipperOrConsShipper,
        pn: "",
        po: "",
        tir: "",
      }));
    return filteredAndMappedData
  } catch (e) {
    console.log('Error occurred while mapping', e)
    return false;
  }
}
