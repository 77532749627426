import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { GET } from '../../utils/axios';
import { formatToK } from '../../utils/global-functions';
import './NavbarStyle.css';

const NavBar = () => {
  const location = useLocation();
  const [activeDropdown, setActiveDropdown] = useState(null); // Track active dropdown index
  const [badgeCount, setBadgeCount] = useState(0);

  const headerLocation = useSelector(
    ({ locationReducer }) => locationReducer.headerLocationValue
  );

  // Function to fetch badge count
  const fetchBadgeCount = async (headerLocation) => {
    try {
      let response = await GET(`edi204/badge-counts/${headerLocation}`);
      if (response?.httpCode === 200) {
        const badgeData = response.data.find(e => e.FLAG === 'P');
        if (badgeData) {
          setBadgeCount(badgeData['TOTAL_ROWS']); // Update badge count from API response
        } else {
          setBadgeCount(0); // Default to 0 if no matching data
        }
      }
    } catch (error) {
      console.error("Failed to fetch badge count:", error);
    }
  };

  // Fetch count on component mount
  useEffect(() => {
    if( typeof headerLocation !== undefined && headerLocation > 0 )
      fetchBadgeCount(headerLocation);
  }, [headerLocation]);

  const taps = [
    {
      label: 'Orders',
      index: 0,
      dropdown: [
        {
          label: 'Orders',
          pathname: '/orders',
          index: 0
        },
        {
          pathname: '/templates',
          label: 'Templates',
          index: 1
        },

        {
          pathname: '/edi-orders',
          label: 'EDI Orders',
          index: 2
        },
        {
          pathname: '/trace',
          label: 'Trace',
          index: 3
        },
        {
          pathname: '/order/completed-order',
          label: 'Completed Order',
          index: 4
        },
      ]
    },
    //     // {
    //     //   pathname: '/tenderList',
    //     //   label: 'Loads',
    //     //   index: 1
    //     // },
    {
      pathname: '/ratebook',
      label: 'Ratebook',
      index: 2
    },
    {
      pathname: '/dispatch',
      label: 'Dispatch',
      index: 3
    },
    {
      label: 'Maintenance',
      index: 4,
      dropdown: [
        {
          label: 'Shipper/Consignee',
          pathname: '/shipperconsignee',
          index: 0
        },
        {
          label: 'Truck/Driver',
          pathname: '/truckdriver',
          index: 1
        },
        {
          label: 'Pickup/Disposition',
          pathname: '/equip/pick-up/dispo',
          index: 2
        },
        {
          label: 'Order By',
          pathname: '/order-by',
          index: 3
        }
      ]
    },
    {
      label: 'Reports',
      index: 5,
      dropdown: [
        {
          label: 'PT Report',
          pathname: '/purchase-transportation-report',
          index: 0
        },
        {
          label: 'Billing Report',
          pathname: '/billing-report',
          index: 0
        },
      ]
    }
  ];

  const handleMouseEnter = (index) => {
    setActiveDropdown(index); // Set active dropdown on hover
  };

  const handleMouseLeave = () => {
    setActiveDropdown(null); // Reset active dropdown on leave
  };

  return (
    <>
      <div style={{ justifyContent: 'flex-start', display: 'flex', alignItems: 'center', height: '100%' }}>
        {taps.map(({ pathname, label, dropdown }, i) => (
          <div key={i} style={{ position: 'relative' }}>
            {dropdown ? (
              <div
                onMouseEnter={() => handleMouseEnter(i)} // Trigger on hover
                onMouseLeave={handleMouseLeave} // Reset on leave
                style={{
                  marginRight: '20px',
                  marginLeft: '30px',
                  cursor: 'pointer',
                  color: 'white',
                  fontWeight: location?.pathname === pathname ? '900' : '400'
                }}
              >
                {i === 0 && badgeCount > 0 && ( // Badge for the first item
                  <span style={{
                    position: 'absolute',
                    top: '-16px',
                    right: '-5px',
                    background: 'red',
                    color: 'white',
                    fontSize: '12px',
                    fontWeight: 'bold',
                    borderRadius: '50%',
                    padding: '5px 8px',
                    zIndex: 1000,
                  }}>
                    {formatToK(badgeCount)}
                  </span>
                )}
                {label}
                {activeDropdown === i && ( // Display dropdown only when active
                  <div style={{
                    position: 'absolute',
                    top: '100%',
                    left: 0,
                    backgroundColor: 'white',
                    padding: '10px',
                    borderRadius: '5px',
                    zIndex: 999,
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', // Add shadow
                    whiteSpace: 'nowrap'
                  }}
                  >
                    {dropdown.map(({ label, pathname }, index) => (
                      <Link
                        key={index}
                        to={pathname}
                        style={{
                          display: 'block',
                          color: (i===0 && index === 2 && badgeCount > 0)?'red':'#0911A7',
                          textDecoration: 'none',
                          padding: '10px 15px',
                          margin: '5px 0',
                          backgroundColor: '#f9f9f9', // Background color for card effect
                          borderRadius: '5px', // Rounded corners for card effect
                          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.1)', // Box shadow for card effect
                          whiteSpace: 'nowrap',
                          transition: 'background-color 0.3s ease'
                        }}
                        className="menu-link"
                        onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#e0e0e0'} // Hover effect
                        onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#f9f9f9'} // Reset effect
                      >
                        {label}
                      </Link>
                    ))}
                  </div>
                )}
              </div>
            ) : (
              <Link
                style={{
                  marginRight: '20px',
                  marginLeft: '30px',
                  textDecoration: 'none',
                  outline: 'none',
                  color: 'white',
                  fontWeight: location?.pathname === pathname ? '900' : '400'
                }}
                to={pathname}
              >
                {label}
              </Link>
            )}
          </div>
        ))}
      </div>
    </>
  );
}

export default NavBar;
