import React, { useState, useRef } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { GET } from "../../utils/axios";

const CustomAutoComplete = ({
    fetchDataEndpoint,         // Endpoint for data fetching (optional)
    queryParams = {},          // Additional query parameters for the API call
    valueChange,               // Callback for value changes
    existingValue = "",        // Initial value for the autocomplete
    isDisabled = false,        // Whether the component is disabled
    filterOptions,             // Function to filter API response data
    optionLabel,               // Function to define option label
    optionKey = "id",          // Unique key for options
    placeholder = "Search...", // Placeholder text
    debounceTime = 500,        // Debounce time for input changes
    staticOptions = [],        // Options provided directly as props
    enableSearch = true,       // Whether to enable remote search
    allowedPattern = /^[a-zA-Z0-9]*$/, // Allowed input pattern (default: alphanumeric)
    maxSize = 50,              // Maximum input size
}) => {
    const [value, setValue] = useState(
        existingValue ? { id: -1, notes: existingValue } : null
    );
    const [options, setOptions] = useState(staticOptions);
    const [error, setError] = useState(""); // State for error messages
    const timeOutRef = useRef(null);

    const handleLookup = async (event, inputValue, reason) => {
        // Validate input against allowed pattern and size
        if (!allowedPattern.test(inputValue)) {
            setError("Input contains invalid characters.");
            return;
        } else if (inputValue.length > maxSize) {
            setError(`Input exceeds maximum size of ${maxSize} characters.`);
            return;
        } else {
            setError(""); // Clear error if input is valid
        }

        if (fetchDataEndpoint && enableSearch && inputValue && reason !== "reset") {
            if (timeOutRef.current) clearTimeout(timeOutRef.current);

            timeOutRef.current = setTimeout(async () => {
                const response = await GET(`${fetchDataEndpoint}`, {
                    params: { ...queryParams, search: inputValue },
                });
                if (response?.status !== "error") {
                    let apiData = response.data || [];
                    if (filterOptions) apiData = filterOptions(apiData);
                    setOptions(apiData);
                } else {
                    setOptions([]);
                }
            }, debounceTime);
        } else if (!fetchDataEndpoint || !enableSearch) {
            // If no endpoint is provided or search is disabled, use staticOptions
            setOptions(staticOptions);
        }
    };

    const handleChange = (event, selectedValue, reason) => {
        if (reason === "clear" || !selectedValue) {
            setValue(null);
            if (valueChange) valueChange(null);
        } else {
            setValue(selectedValue);
            if (valueChange && error==='') valueChange(selectedValue);
        }
    };

    const getOptionLabel = (option) => {
        if (typeof option === "string") return option;
        if (optionLabel) return optionLabel(option);
        return option?.notes || "";
    };

    return (
        <div>
            <Autocomplete
                disabled={isDisabled}
                freeSolo
                autoSelect
                options={options}
                getOptionLabel={getOptionLabel}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        name="autocomplete"
                        variant="standard"
                        placeholder={placeholder}
                        error={!!error}
                        helperText={error}
                        inputProps={{
                            ...params.inputProps,
                            maxLength: maxSize, // Enforce max size directly in the input field
                        }}
                        sx={{
                            input: {
                                //textTransform: "uppercase",
                            },
                        }}
                    />
                )}
                renderOption={(props, option) => (
                    <li
                        {...props}
                        key={option[optionKey]}
                        style={{ borderBottom: "1px solid #E0E0E0" }}
                    >
                        {optionLabel ? optionLabel(option) : option?.notes || ""}
                    </li>
                )}
                componentsProps={{ popper: { style: { width: 300 } } }}
                onInputChange={handleLookup}
                onChange={handleChange}
                value={value}
            />
        </div>
    );
};

export default CustomAutoComplete;
