import { rateBookTypes } from "../types/rateBookTypes";
import { Dispatch } from "redux";
import { GET, POST, PUT, DELETE } from "../../utils/axios";
import { calRatio, calMargin, calRevenue, calRevenueForImport, validateField, validateRatebookData, validateSingleField, exportResultToCsv, sanitizeString } from "../../scenes/RateBook/CommonFn";


const setLoader = (loadState: Boolean) => {
  return {
    type: rateBookTypes.SET_LOADING,
    payload: loadState,
  };
};

//Rate Book GET Api
export const getRateBookData = (rateBookApiValues: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoader(true));
    let res = await getRateBookDataReq(rateBookApiValues);
    dispatch(setRateBookData(res));
    dispatch(setLoader(false));
    return res;
  };
};

export const getRateBookDataReq = async (rateBookApiValues: any) => {
  try {
    const pageQry = (rateBookApiValues.pageNumber) ? `page=${rateBookApiValues.pageNumber}` : '';
    const pageSizeQry = (rateBookApiValues.pageSize) ? `&pageSize=${rateBookApiValues.pageSize}` : '';
    const locationQry = (rateBookApiValues.locationNumber) ? `&locationNumber=${rateBookApiValues.locationNumber}` : '';
    const sortByQry = (rateBookApiValues.sortBy) ? `&sortBy=${rateBookApiValues.sortBy}` : '';
    const sortOrderQry = (rateBookApiValues.sortOrder) ? `&sortOrder=${rateBookApiValues.sortOrder}` : '';
    const searchByQry = (rateBookApiValues.searchBy) ? `&searchBy=${rateBookApiValues.searchBy}` : '';
    const typeQry = (rateBookApiValues.type) ? `&type=${rateBookApiValues.type}` : '';

    let res = await GET(
      `/rate-book/?${pageQry + pageSizeQry + locationQry + sortByQry + sortOrderQry + searchByQry + typeQry}`
    );
    // res.data.data.forEach((ele: any, i: any) => {
    //   const pager = ((rateBookApiValues.pageNumber - 1) * rateBookApiValues.pageSize);
    //   ele.sno = (pager === 0) ? 1 + i : pager + 1 + i;
    // });
    //console.log(res);
    return res;
  } catch (err) {
    return [];
  }
};

export const setRateBookData = (rateBookData: any) => {
  return {
    type: rateBookTypes.GET_RATEBOOK,
    payload: rateBookData,
  };
};

//Rate Book Add Api
export const addRateBookData = (postReqParams: any) => {
  return async (dispatch: Dispatch) => {
    let res = await addRateBookDataReq(postReqParams);
    dispatch(setAddRateBookData(res));
    return res;
  };
};

export const addRateBookDataReq = async (postReqParams: any) => {
  try {
    let res = await POST(`/rate-book/`, postReqParams);
    if (res === undefined || res?.status === 'error') {
      if (res?.httpCode === 409) {
        return {
          status: 'error',
          message: res.message
        };
      }
      return {
        status: 'error',
        message: 'Bad Data Supplied to API, Please try again with correct data!'
      };
    }
    return {
      status: 'success',
      message: 'Ratebook data has been updated successfully!'
    };

  } catch (err) {
    return {
      status: 'error',
      message: 'Bad Data Supplied to API, Please try again with correct data!'
    };
  }
};

const setAddRateBookData = (apiResMessage: any) => {
  return {
    type: rateBookTypes.ADD_RATEBOOK,
    payload: apiResMessage,
  };
};

//Rate Book DELETE Api
export const delRateBookData = (autoId: number) => {
  return async (dispatch: Dispatch) => {
    //dispatch(setLoader(true));
    let res = await delRateBookDataReq(autoId);
    dispatch(setDelRateBookData(res));
    //dispatch(setLoader(false));
  };
};

export const delRateBookDataReq = async (autoId: number) => {
  try {
    let res = await DELETE(`/rate-book/` + autoId);
    return res;
  } catch (err) {
    return [];
  }
};

const setDelRateBookData = (rateBookData: any) => {
  return {
    type: rateBookTypes.DELETE_RATEBOOK,
    payload: rateBookData,
  };
};

// GET GL Codes
export const getGLCodes = (all=false) => {
  return async (dispatch: Dispatch) => {
    let res = await getGLCodesReq(all);
    if(all){
      dispatch(setGLCodesAll(res));
    }else{
      dispatch(setGLCodes(res));
    }
    return res;
  };
};
export const getGLCodesReq = async (all:boolean) => {
  try {
    let res = await GET(`/glcodes`);
    
    if(all){
      return res;
    }else{
      return res.filter((e:any) => e.glIsActive === 'Y');
    }
  } catch (err) {
    return [];
  }
};

const setGLCodes = (GLCodeData: any) => {
  return {
    type: rateBookTypes.GET_GL_CODE,
    payload: GLCodeData,
  };
};
const setGLCodesAll = (GLCodeDataAll: any) => {
  return {
    type: rateBookTypes.GET_GL_CODE_ALL,
    payload: GLCodeDataAll,
  };
};

export const cityLookup = (search: any) => {
  return async (dispatch: Dispatch) => {
    let res = await GET(`/cities/search/${search}`);
    dispatch(setCities(res));
    return res;
  };
};

export const stateLookup = (search: any) => {
  return async (dispatch: Dispatch) => {
    let res = await GET(`/states/search/${search}`);
    dispatch(setStates(res));
    return res;
  };
};

// GET STATES
export const getStates = () => {
  return async (dispatch: Dispatch) => {
    let res = await getStatesReq();
    dispatch(setStates(res));
  };
};
export const getStatesReq = async () => {
  try {
    let res: any = [];
    let getStates = await GET(`/states`);
    getStates.forEach((ele: { id: any; statePrefix: any }) => {
      res.push({
        id: ele.id,
        name: ele.statePrefix,
      });
    });
    return res;
  } catch (err) {
    return [];
  }
};

const setStates = (stateData: any) => {
  return {
    type: rateBookTypes.GET_STATES,
    payload: stateData,
  };
};

// GET CITY
export const getCities = () => {
  return async (dispatch: Dispatch) => {
    let res = await getCitiesReq();
    dispatch(setCities(res));
  };
};
export const getCitiesReq = async () => {
  try {
    let res: any = [];
    let getCities = await GET(`/cities`);
    getCities.forEach((ele: { id: any; city: any }) => {
      res.push({
        id: ele.id,
        name: ele.city,
      });
    });
    return res;
  } catch (err) {
    return [];
  }
};

const setCities = (cityData: any) => {
  return {
    type: rateBookTypes.GET_CITIES,
    payload: cityData,
  };
};

// GET Export values
export const getExportedValues = (locationNumber: number) => {
  return async (dispatch: Dispatch) => {
    let res = await getExportedValuesReq(locationNumber);
    dispatch(setExportedValues(res));
  };
};
export const getExportedValuesReq = async (locationNumber: number) => {
  try {
    let res = await GET(`/rate-book/?locationNumber=${locationNumber}`);
    res.data.data.forEach((item: any, i: number) => {
      // let a = 1
      // let b = 2
      // let c = add(a, b)
      // item.sNo = i + 1;
      item.payableRatioContract = calRatio(item.payableRateContract, item.receivableRates);
      item.payableRatioBroker = calRatio(item.payableRateBroker, item.receivableRates);
      item.marginContractor = calMargin(item.receivableRates, item.payableRateContract);
      item.marginBroker = calMargin(item.receivableRates, item.payableRateBroker);
      item.revenuePerMile = calRevenue(item.receivableRates, item.totMiles);
      item.contractorCPM = calRevenue(item.totMiles, item.payableRateContract);
      item.brokerCPM = calRevenue(item.receivableRates, item.payableRateBroker);
    });
    return res;
  } catch (err) {
    return [];
  }
};

export const setExportedValues = (exportedValues: any) => {
  return {
    type: rateBookTypes.GET_EXPORT_VALUE,
    payload: exportedValues,
  };
};

const filterImportedJson = async (jsonObj: any, locationNumber: number) => {
  let mapImportedFieldsArr: any = [];
  let retObj: any = {};
  let passed: number = 0;
  let failed: number = 0;
  let failedItems: any = [];
  await jsonObj.map((obj: any, indx: number) => {
    let validRes: any = validateRatebookData(obj);
    if (validRes.status) {
      failed += 1;
      //failed.push(obj['Sl No']);
      let customMsg = [
        validRes.message,
        obj['Id'],
        obj['Rate Name'] || '',
        obj['Invoice Description'] || '',
        obj['GL Description'] || '',
        obj['GL Code'] || 0,
        obj['Ramp/Port/CY'] || '',
        obj['State'] || '',
        obj['Destination City'] || '',
        obj['Destination State'] || '',
        obj['RT Miles'] || 0,
        obj['Receivable Rates'] || 0,
        obj['Order By'] || '',
        obj['L/A'] || '',
        obj['Payable Rate (Contractor)'] || 0,
        obj['Payable Rate (Broker)'] || 0,
        obj['Edi210'] || 'N'
      ]
      failedItems.push(customMsg);
      return false;
    }
    mapImportedFieldsArr.push(
      {
        locationNumber: locationNumber,
        autoId: obj['Id'] || indx,
        notes: sanitizeString(obj['Rate Name']) || '',
        invoiceDesc: sanitizeString(obj['Invoice Description']) || '',
        GLCode: obj['GL Code'] || 0,
        GLDesc: sanitizeString(obj['GL Description']) || '',
        originCity: sanitizeString(obj['Ramp/Port/CY']) || '',
        originState: sanitizeString(obj['State']) || '',
        destinationCity: sanitizeString(obj['Destination City']) || '',
        destinationState: sanitizeString(obj['Destination State']) || '',
        totMiles: obj['RT Miles'] || 0,
        billTo: sanitizeString(obj['Order By']) || '',
        receivableRates: obj['Receivable Rates'] || 0,
        LorA: obj['L/A'] || '',
        payableRateContract: obj['Payable Rate (Contractor)'] || 0,
        payableRateBroker: obj['Payable Rate (Broker)'] || 0,
        payableRatioContract: obj['Payable Ratio (Contractor)'] || calRatio(obj['Payable Rate (Contractor)'], obj['Receivable Rates']),
        payableRatioBroker: obj['Payable Ratio (Broker)'] || calRatio(obj['Payable Rate (Broker)'], obj['Receivable Rates']),
        marginContractor: obj['Margin (Contractor)'] || calMargin(obj['Receivable Rates'], obj['Payable Rate (Contractor)']),
        marginBroker: obj['Margin (Broker)'] || calMargin(obj['Receivable Rates'], obj['Payable Rate (Broker)']),
        revenuePerMile: Number(calRevenueForImport(obj['Receivable Rates'] || 0, obj['RT Miles'] || 0)) || 0,
        contractorCRM: Number(calRevenueForImport(obj['Payable Rate (Contractor)'] || 0, obj['RT Miles'] || 0)) || 0,
        brokerCPM: Number(calRevenueForImport(obj['Payable Rate (Broker)'] || 0, obj['RT Miles'] || 0)) || 0,
        edi210: obj['Edi210'] || 'N'
      }
    );
    passed += 1;
    //passed.push(obj['Sl No']);
  })
  failedItems.unshift([
    'Error Message',
    'Id',
    'Rate Name',
    'Invoice Description',
    'GL Description',
    'GL Code',
    'Ramp/Port/CY',
    'State',
    'Destination City',
    'Destination State',
    'RT Miles',
    'Receivable Rates',
    'Order By',
    'L/A',
    'Payable Rate (Contractor)',
    'Payable Rate (Broker)',
    'Edi210'
  ])
  exportResultToCsv('failed_ratebook_records.csv', failedItems);
  //console.table(failedItems);
  //console.log('Failed Items:', failedItems)
  Object.assign(retObj, { failed, passed, filteredData: mapImportedFieldsArr })
  return retObj;
}

// Import values
export const importRateBookData = (data: any, locationNumber: number) => {

  return async (dispatch: Dispatch) => {
    let retObj = await filterImportedJson(data, locationNumber);
    if (retObj.filteredData.length > 0) {

      const chunkSize = 300;
      let res;
      for (let i = 0; i < retObj.filteredData.length; i += chunkSize) {
        const chunk = retObj.filteredData.slice(i, i + chunkSize);
        res = await importRateBookDataReq(chunk);
        dispatch(setImportRateBookRes(res));
        Object.assign(res, { "failed: ": retObj.failed, "passed: ": retObj.passed })
      }
      //let res = await importRateBookDataReq(retObj.filteredData);
      //dispatch(setImportRateBookRes(res));
      //Object.assign(res, { "failed: ": retObj.failed, "passed: ": retObj.passed })
      return res;
    }
    return {
      status: 'error',
      message: 'Validation Error - Malicious records found!',
      "failed: ": retObj.failed,
      "passed: ": retObj.passed
    };
  };
};
export const importRateBookDataReq = async (postData: any) => {
  try {
    let res = await POST(`/rate-book/import`, postData);
    //console.log('------', res)
    if (res === undefined || res.status === 'error') {
      return {
        status: 'error',
        message: 'Bad Data Supplied to API, Please try again after sometime!'
      };
    }
    return {
      status: 'success',
      message: 'Ratebook data has been uploaded successfully!'
    };

  } catch (err) {
    return {
      status: 'error',
      message: 'Something went wrong, Please try again after sometime!'
    };
  }
};

const setImportRateBookRes = (importedValues: any) => {
  return {
    type: rateBookTypes.GET_IMPORT_VALUE,
    payload: importedValues,
  };
};

// get Location Details
export const locationHeaderValue = (data: any) => {
  return async (dispatch: Dispatch) => {
    // let res = await locationHeaderValues(data.locationNumber);
    dispatch(locationHeaderValues(data?.locationNumber));
  };
};
// export const locationHeaderValueReq = async () => {
//   try {
//     // let res = await GET(`/glcodes`);
//     let res = [
//       { Name: 123457, Age: "GL Code Desc1" },
//       { Name: 123458, Age: "GL Code Desc2" },
//       { Name: 123459, Age: "GL Code Desc3" },
//     ];
//     return res;
//   } catch (err) {
//     return [];
//   }
// };

const locationHeaderValues = (headerLocationValues: any) => {
  return {
    type: rateBookTypes.GET_HEADER_LOCATION_VALUE,
    payload: headerLocationValues,
  };
};


//Get Search Api Data

export const getSearchRateBookData = (rateBookApiValues: any) => {

  return async (dispatch: Dispatch) => {
    dispatch(setLoader(true));
    let res = await getSearchRateBookDataReq(rateBookApiValues);

    dispatch(setRateBookData(res));

    dispatch(setLoader(false));
  };
};

export const getSearchRateBookDataReq = async (rateBookApiValues: any) => {

  try {
    let res = await GET(
      `/rate-book/?page=${rateBookApiValues.pageNumber}&pageSize=${rateBookApiValues.pageSize}&locationNumber=${rateBookApiValues.getLocationNumber}&searchBy=${rateBookApiValues.searchBy}`
    );
    res.data.data.forEach((ele: any, i: any) => {
      ele.sno = i + 1;
    });
    //console.log(res);
    return res.data;
  } catch (err) {
    return [];
  }
};

// export const setRateBookData = (rateBookData: any) => {
//   return {
//     type: rateBookTypes.GET_RATEBOOK,
//     payload: rateBookData,
//   };
// };