import moment from 'moment';
export function getPayableGlCode(order_type, recievable_gl_code) {
    //if (order_type === 'INBOUND' || order_type === 'OUTBOUND') {
        let payable_gl_code_textField;
        switch (recievable_gl_code) {
            case 3318000:
            case 3324000:
                payable_gl_code_textField = 6988000;
                break;
            // case 3322000:
            // case 3620000:
            // case 3540000:
            // case 3535000:
            //     //if (['INBOUND','OUTBOUND','EXPORT','IMPORT'].includes(order_type)) {
            //     payable_gl_code_textField = 6980000;
            //     //}
            //     // if (order_type === 'INBOUND' || order_type === 'OUTBOUND') {
            //     //     payable_gl_code_textField = 6980000;
            //     // }
            //     // if (order_type === 'EXPORT' || order_type === 'IMPORT') {
            //     //     payable_gl_code_textField = 6985000;
            //     // }
            //     break;
            // case 3535000:
            //     payable_gl_code_textField = 6900000;
            //     break;
            case 3660000:
                payable_gl_code_textField = 6566000;
                break;
            // case 2026000:
            //     payable_gl_code_textField = 2026000;
            //     break;
            // case 2035000:
            //     payable_gl_code_textField = 2035000;
            //     break;
            case 3645000:
                payable_gl_code_textField = 7045000;
                break;
            case 7755000:
                payable_gl_code_textField = 7755000;
                break;
            case 9200000:
                payable_gl_code_textField = 9200000;
                break;
            // case 2039000:
            //     payable_gl_code_textField = 2039000;
            //     break;
            // case 4150000:
            //     payable_gl_code_textField = 4150000;
            //     break;
            default:
                if(recievable_gl_code !== 3810000){
                    //if (['INBOUND','OUTBOUND','EXPORT','IMPORT'].includes(order_type)) {
                    payable_gl_code_textField = 6980000;
                    //}
                    // if (order_type === 'EXPORT' || order_type === 'IMPORT') {
                    //     payable_gl_code_textField = 6985000;
                    // }
                }else{
                    payable_gl_code_textField = 6982000
                }
                break;
        }
        return payable_gl_code_textField; 
        // if (recievable_gl_code === 3318000 || recievable_gl_code === 3324000) {
        //     let payable_gl_code_textField = 6988000;
        //     return payable_gl_code_textField;
        // } else if (recievable_gl_code !== 3810000 || [3322000, 3620000, 3540000, 3545000].includes(recievable_gl_code)) {
        //     let payable_gl_code_textField = 6980000;
        //     return payable_gl_code_textField;
        // } else {
        //     let payable_gl_code_textField = 6982000;
        //     return payable_gl_code_textField;
        // }
    //}

    // if (order_type === 'EXPORT' || order_type === 'IMPORT') {
    //     if (recievable_gl_code === 3318000 || recievable_gl_code === 3324000) {
    //         let payable_gl_code_textField = 6988000;
    //         return payable_gl_code_textField;
    //     } else if (recievable_gl_code !== 3810000 || [3322000, 3620000, 3540000, 3545000].includes(recievable_gl_code)) {
    //         let payable_gl_code_textField = 6985000
    //         return payable_gl_code_textField;
    //     } else {
    //         let payable_gl_code_textField = 6982000
    //         return payable_gl_code_textField;
    //     }
    // }
}

export function getParentPayableRate(recievable_gl_code, brokerRate, contractorRate) {
    if (recievable_gl_code === 3810000) {
        let payable_rate = brokerRate
        return payable_rate;
    } else {
        let payable_rate = contractorRate;
        return payable_rate;
    }
}
/**
 * created on: 04-oct-2023
 * Business requirement has changed
 * They need percentage in decimals - for eg. 70% => 0.70% 
 * created mask percentage function to overwrite the percentage.
 */
export function maskPercentage(perc) {
    return Number(perc) / Number(100);
}
export function getParentPayableUnit(recievable_gl_code, brokerRatio, contractorRatio) {
    if (recievable_gl_code === 3810000) {
        return maskPercentage(brokerRatio);
    } else {
        return maskPercentage(contractorRatio);
    }
}

export function getSubItemPayableRate(recievable_subitem_rate, recievable_gl_code, FSC_broker, FSC_contractor) {
    if (recievable_gl_code === 3810000) {
        let payable_rate = (recievable_subitem_rate * FSC_broker / 100)
        return Number(payable_rate).toFixed(2);
    } else {
        let payable_rate = recievable_subitem_rate * FSC_contractor / 100
        return Number(payable_rate).toFixed(2);
    }
}
export function getSubitemReceivableGL(parent_gl_code) {
    if (parent_gl_code !== 3323000) {
        let subitem_gl_code = 3318000
        return subitem_gl_code;
    }
    if (parent_gl_code === 3323000) {
        let subitem_gl_code = 3324000
        return subitem_gl_code;
    }
}

export function getSubitemPayableGL(GLCode) {
    if (GLCode == 3318000 || 3324000) {
        let payable_subitem_gl_code_textField = 6988000
        return payable_subitem_gl_code_textField;
    }
}

export function getSubItemUnit(glcode, fscObj) {
    if (glcode === 3810000) {
        return maskPercentage(fscObj.fsc_per_b);
    } else {
        return maskPercentage(fscObj.fsc_per_c);
    }
}
export function getRecSubItemUnit(fscObjPerc) {
    return maskPercentage(fscObjPerc);
}
export function calAmountByPer(num, percentage) {
    // let amount = Number(num) * (Number(percentage) / 100) // commented on 04-oct-2023
    let amount = Number(num) * (Number(percentage))
    return roundOfNumber(amount);
}
export function calAmount(rate, unit) {
    const total = Number(rate) * Number(unit)
    return roundOfNumber(total);
}

export function roundOfNumber(num) {
    const newNum = Number(num).toFixed(2);
    return newNum;
}

export function findMaxLineNumber(lineItems) {
    let lineNumberArr = [];

    lineItems?.length > 0 && lineItems.map((item) => {
        lineNumberArr.push(item?.receivable?.lineNumber || 0);
        lineNumberArr.push(item?.payable?.lineNumber || 0);

        // Fill Receivable SubItems
        if (item.receivable?.subItems?.length > 0) {
            item.receivable?.subItems.map((item) => {
                lineNumberArr.push(item?.lineNumber || 0);
            })
        }
        // Fill Payable SubItems
        if (item.payable?.subItems?.length > 0) {
            item.payable?.subItems.map((item) => {
                lineNumberArr.push(item?.lineNumber || 0);

                if (item?.splitItems?.length > 0) {
                    item.splitItems.map((item) => {
                        lineNumberArr.push(item?.lineNumber || 0);
                    })
                }
            })
        }

        // Fill Payable splitItems
        if (item.payable?.splitItems?.length > 0) {
            item.payable?.splitItems.map((item) => {
                lineNumberArr.push(item?.lineNumber || 0);
            })
        }
    })

    if (lineNumberArr.length > 0) {
        const maxNumber = Math.max(...lineNumberArr);
        return Number(maxNumber);
    }
    return 0
}

export const sanitizeAmountField = (value) => {
    if (typeof value === 'undefined' || value === null) {
        return '';
    } else {
        return Number(value).toFixed(2);
    }
}
export const sanitizePercentageField = (value) => {
    if (typeof value === 'undefined' || value === null) {
        return '';
    } else {
        return Number(value).toFixed(2); //+ '%'; // SCRUM-101 requirement - remove % sign from all the units
    }
}

export const calcTotalRevenue = (data) => {
    const totalRevenue = data.reduce((total, item) =>
        Number(item.receivable?.amount || 0) + Number(total) + getSubItemsTotal(item.receivable?.subItems, 'receivable')
        , 0)
    return Number(totalRevenue).toFixed(2);
}

// export const calcTotalRevenueExcludingUnPayableGL = (data, glcodeArr) => {
//     const totalRevenue = data.reduce((total, item) => !glcodeArr.includes(item.receivable?.glCode) ? Number(item.receivable?.amount || 0) + Number(total) + getSubItemsTotal(item.receivable?.subItems, 'receivable') : 0, 0)
//     return Number(totalRevenue).toFixed(2);
// }
export const calcTotalRevenueExcludingUnPayableGL = (data, glcodeArr) => {
    let total = 0;
    if (data?.length > 0) {
        data.map((item) => {
            if (glcodeArr.includes(item.receivable?.glCode) === false) {
                total += Number(item.receivable?.amount || 0);
            }
            if (item.receivable?.subItems?.length > 0) {
                item.receivable.subItems.map((sub_item) => {
                    if (glcodeArr.includes(sub_item?.glCode) === false) {
                        total += Number(sub_item?.amount || 0);
                    }
                })
            }
        })
        console.log('total revenue excluding unpayable glcodes: ', total)
        return Number(total).toFixed(2);
    }
    return total;
}

export const calcTotalExpense = (data) => {
    const totalRevenue = data.reduce((total, item) =>
        Number(item.payable?.amount || 0) + Number(total) + getSubItemsTotal(item.payable?.subItems, 'payable') + getSubItemsTotal(item.payable?.subItems?.[0]?.splitItems, 'payable') + getSubItemsTotal(item.payable?.splitItems, 'payable')
        , 0)
    return Number(totalRevenue).toFixed(2);
}

export const calcPTNet = (revenue, expense) => { // Revenue excluding un payable glcodes
    return (Number(revenue) - Number(expense)).toFixed(2)
}

export const calcTotalPTNet = (revenue, expense) => {
    return (Number(revenue) - Number(expense)).toFixed(2)
}
export const calcPTPercentage = (revenue, expense) => { // Revenue excluding un payable glcodes
    let totPerc = (Number(expense) / Number(revenue) * 100).toFixed(2)
    if (isNaN(totPerc)) {
        return 0;
    }
    return totPerc;
}

export const calcTotalPercentage = (revenue, expense) => {
    let totPerc = (Number(expense) / Number(revenue) * 100).toFixed(2)
    if (isNaN(totPerc)) {
        return 0;
    }
    return totPerc;
}

const getSubItemsTotal = (data, type) => {
    if (data?.length > 0) {
        const subItemTotal = data.reduce((total, item) => Number(item?.amount || 0) + Number(total), 0);
        return Number(subItemTotal);
    }
    return 0;
}

export const getDate = (dateVal) => {
    if (dateVal === 0 || !dateVal)
        return ""
    if (moment(dateVal, 'YYYY-MM-DD', true).isValid()) {
        return dateVal;
    } else {
        let date = dateVal.toString().substring(1)
        date = `20${date.substring(0, 2)}-${date.substring(2, 4)}-${date.substring(4)}`
        return date;
    }
}

export const convertDateToMDY = (dateVal) => {
    if (dateVal === 0 || !dateVal) {
        return ""
    } else if (dateVal?.toString().length === 7) {
        let date = dateVal.toString().substring(1)
        date = `20${date.substring(0, 2)}-${date.substring(2, 4)}-${date.substring(4)}`
        if (moment(date, 'YYYY-MM-DD', true).isValid())
            return moment(date).format('MM/DD/YY');
        return "";
    } else if (moment(dateVal, 'YYYY-MM-DD', true).isValid()) {
        return moment(dateVal).format('MM/DD/YY');
    } else {
        return '';
    }
}

export const convertStrToUpperCase = (str) => {
    if (typeof str !== 'undefined' && str !== '' && str !== null) {
        return str.toUpperCase();
    }
    return '';
}