import { validateDateField } from "./validateDateField"


const getRCDepartment = (glCode) => {
  const glCodeArr = [2026000, 2035000, 2175000, 2177000];
  if (glCodeArr.includes(glCode)) {
    return 0;
  }
  return 310;
}

const recAgainstLineNumber = (obj) => {
    if (typeof obj?.lineNumber !== 'undefined' && obj?.lineNumber !== 0) {
      return obj.lineNumber;
    }
    return 0;
  }

const ratesChargesObjsForServer = (obj) => {
    const res = {
      lineNumber: obj.lineNumber,
      description: obj.description || "",
      notes: obj.notes || "",
      glDescription: obj.glDescription || "",
      glCode: obj.glCode || "",
      originName: obj.originName || "",
      originCity: obj.originCity || "",
      originPrefix: obj.originPrefix || "",
      destinationCity: obj.destinationCity || "",
      destinationStatePrefix: obj.destinationStatePrefix || "",
      accountLine: obj.accountLine || "",
      unitRate: obj.unitRate || "",
      units: obj.units || "",
      amount: obj.amount || "",
      transmitted: obj.transmitted || "",
      truckNumber: obj.truckNumber || "",
      parentLineNumber: obj.parentLineNumber || "",
      splitLineNumber: obj.splitLineNumber || "",
      loadType: obj.loadType || "",
      subPer: obj.subPer || "",
      payRec: obj.payRec || "",
      payType: obj.payType || "",
      payableRecievable: obj.payableReceivable || "",
      processedDate: validateDateField(obj.processedDate),
      department: getRCDepartment(obj.glCode),
      recAgainstSplitLine: obj.recAgainstSplitLine || 0,
      splitType: obj.splitType || '',
      commonSplitLine: obj.commonSplitLine || 0,
    }
    return res
  }

export const normalizeRates = (item) => {
    let ratesChargesarray = [];
      console.log('rates charge saving', item);
      item.ratecharges?.map((item, indx) => {
        let rowIndex = Number(indx) + 1;
        if (item.payable.payableReceivable === 'P') {
          const { subItems: _, splitItems: __, ...newObj } = item?.payable;
  
          ratesChargesarray.push(ratesChargesObjsForServer(newObj));
  
          if (Object.keys(item?.payable).length !== 0) {
            item?.payable?.subItems?.length > 0 && item?.payable?.subItems?.map(subitem => {
              //if (subitem?.splitItems?.length === 0) {
              ratesChargesarray.push(ratesChargesObjsForServer(subitem));
              //}
              subitem?.splitItems?.map(splititem => {
                ratesChargesarray.push(ratesChargesObjsForServer({ ...splititem, recAgainstSplitLine: recAgainstLineNumber(item.receivable?.subItems?.[0]), splitType: 'C', commonSplitLine: rowIndex }));
              })
            })
  
            item?.payable?.splitItems?.map(splitItems => {
              ratesChargesarray.push(ratesChargesObjsForServer({ ...splitItems, recAgainstSplitLine: recAgainstLineNumber(item.receivable), splitType: 'P', commonSplitLine: rowIndex }));
            })
  
          }
        }
  
        if (typeof item.payable.payableReceivable === 'undefined' && item.payable?.splitItems?.length > 0) {
  
          item?.payable?.splitItems?.map(splitItems => {
            ratesChargesarray.push(ratesChargesObjsForServer({ ...splitItems, recAgainstSplitLine: recAgainstLineNumber(item.receivable), splitType: 'P', commonSplitLine: rowIndex }));
          })
  
          if (item?.payable?.subItems?.length > 0 && item?.payable?.subItems?.[0]?.splitItems?.length > 0) {
            item.payable?.subItems?.[0]?.splitItems?.map(splititem => {
              ratesChargesarray.push(ratesChargesObjsForServer({ ...splititem, recAgainstSplitLine: recAgainstLineNumber(item.receivable?.subItems?.[0]), splitType: 'C', commonSplitLine: rowIndex }));
            })
          }
  
        }
  
        if (item.receivable.payableReceivable === 'R') {
  
          const { subItems: _, splitItems: __, ...newObj } = item?.receivable;
          ratesChargesarray.push(ratesChargesObjsForServer(newObj));
  
          if (Object.keys(item?.receivable).length !== 0) {
  
            item?.receivable?.subItems?.length > 0 && item?.receivable?.subItems?.map(subitem => {
              ratesChargesarray.push(ratesChargesObjsForServer(subitem));
            })
  
          }
        }
      });
  
      const ratesCharges = ratesChargesarray.sort((a, b) => a.lineNumber - b.lineNumber).filter(item => item.lineNumber !== '');
      return ratesCharges;
  }