import { ediOrderTypes} from '../types/ediOrderType';
import { AnyAction, Dispatch } from 'redux';
import { GET, POST, PUT, DELETE } from '../../utils/axios';


export const getEdiOrderData = (params: any) => {
    return async (dispatch: Dispatch) => {
         const res = await getEdiOrderDataReq(params);
         console.log("^^^res",res)
         if (res.httpCode===200 && res?.data) {
            dispatch(setEdiOrderData(res?.data));
         }else{
        //  if (res.httpCode===200 && res?.data) {
        //     dispatch(setEdiOrderData(res?.data));
        //  }else{
            dispatch(setEdiOrderData([]));
         }
       
        return res;
    }
}


const setEdiOrderData = (apiData: any) => {
    return {
        type:ediOrderTypes.EDI_DATA,
        payload: apiData,
    };
};

const SaveEdiOrderData = (apiData: any) => {
    return {
        type: ediOrderTypes.EDI_SAVE,
        payload: apiData,
    };
};


export const getEdiOrderDataReq = async (params: any) => {
    console.log("params",params)

    // let url = `/elastic/templates/${params.locationNumber}`;
    // const url = `/edi204/list`;
    
    const url =`edi204/list/${params.locationNumber}`
   
    let queryString = `?flag=${params.ediListType}&page=${params.query.page}&size=${params.query.pageSize}&sortBy=${params.sortData.field}&sortOrder=${params.sortData.sort}`;
    if(params?.search){
        queryString  +=`&search=${params?.search}`
    }
    try {
        let res = await GET(url+queryString);
        console.log("res",res)
        return res?.data;
    } catch (err) {
        return [];
    }
   
}


export const getSingleEdiOrderData = (req:any) => {
    return async (dispatch: Dispatch) => {
        const res = await singleEdiOrderApiReq(req);
        dispatch(setEdiOrderData(res));
        return res;
    };
}

export const singleEdiOrderApiReq = async (req:any) => {
    try {
        
        // let res = await GET(`/trucks/driver/maintenance/single/${truckNumber}/${driverNumber}`)
        if(req.selectedRowId){
            let res = await GET(`/edi204/${req.selectedRowId}`)
           
            if (res && res.httpCode === 200 && res.status === 'success')  {
          
            return res.data;
               
            }

        }else{
            
            return [];

        }
  
    } catch (err) {
        return [];
    }
}


export const acceptEdiOrder = (LocationNumber: any,ediOrderId:any,postData:any) => {
    return async (dispatch: Dispatch) => {
         const res = await acceptEdiOrderReq(LocationNumber,ediOrderId,postData);
        dispatch(setEdiOrderData(res));
        return res;
    }
}

export const acceptEdiOrderReq = async (locationNumber: number,ediOrderId:any,postData:any) => {
    try {
        let res = await POST(`/edi204/accept/${locationNumber}/${ediOrderId}`,postData)
        // let res = await POST(`/orders/order/${locationNumber}`,postData)
        return res;
    } catch (err) {
        return [];
    }
}

export const rejectEdiOrder = (ediOrderId:any,postData:any,buttonType:any) => {
    return async (dispatch: Dispatch) => {
        const urlType =buttonType==="Acknowledge"?"ack":"reject"
         const res = await rejectEdiOrderReq(ediOrderId,postData,urlType);
        dispatch(setEdiOrderData(res));
        return res;
    }
}

export const rejectEdiOrderReq = async (ediOrderId:any,postData:any,urlType:any) => {
    try {
      
        let res = await POST(`/edi204/${urlType}/${ediOrderId}`,postData);
        return res;
    } catch (err) {
        return [];
    }
}

export const getEdiOrderDriverList = (locationNumber: any) => {
    return async (dispatch: Dispatch) => {
        try {
            let res = await GET(`drivers/list/${locationNumber}`);
            if (res && res.httpCode === 200 && res.status === 'success') {
                return res.data;
            } else {
                return [];
            }
        } catch (err) {
            return [];
        }
    };
};
export const getEdiOrderRampList = (query: any) => {
    return async (dispatch: Dispatch) => {
        try {
            let res = await GET(`edi204/ramps/list/${query}`);
            if (res && res.httpCode === 200 && res.status === 'success') {
                return res.data;
            } else {
                return [];
            }
        } catch (err) {
            return [];
        }
    };
};

export const assignLocation = (locationNumber:number,ediOrderId:number) => {
    return async (dispatch: Dispatch) => {
        const res = await assignLocationReq(locationNumber,ediOrderId);
       dispatch(setEdiOrderData(res));
       return res;
   }
}

const assignLocationReq = async (locationNumber:number, ediOrderId:number) => {
    try {
        let res = await PUT(`/edi204/assign/location/${locationNumber}/${ediOrderId}`);
        return res;
    } catch (err) {
        return [];
    }
}
 
export const unAssignLocation = (ediOrderId:number) => {
    return async (dispatch: Dispatch) => {
       const res = await unAssignLocationReq(ediOrderId);
       dispatch(setEdiOrderData(res));
       return res;
   }
}

const unAssignLocationReq = async (ediOrderId:number) => {
    
    try {
        let res = await PUT(`/edi204/unassign/location/${ediOrderId}`, {});
        return res;
    } catch (err) {
        console.log("Error unassign..", err);
        return [];
    }

}
export const restoreEdiOrder = (ediOrderId:AnyAction) => {
    return async (dispatch: Dispatch) => {
         const res = await restoreEdiOrderReq(ediOrderId);
        dispatch(setEdiOrderData(res));
        return res;
    }
}

export const restoreEdiOrderReq = async (ediOrderId:any) => {
    try {
        let res = await PUT(`/edi204/restore/${ediOrderId}`)
        return res;
    } catch (err) {
        return [];
    }
}


export const addBulkContacts = (postData:any) => {
    return async (dispatch: Dispatch) => {
         const res = await addBulkContactsReq(postData);
        dispatch(setEdiOrderData(res));
        return res;
    }
}

export const addBulkContactsReq = async (postData:any) => {
    try {
        let res = await POST(`/contacts/bulk/add`,postData)
      
        return res;
    } catch (err) {
        return [];
    }
}