import moment from 'moment';
import { normalizeRates } from './normalizeRates';
const isZeroOrEmpty = (value) => {
  return value === 0 || value === '';
}

export const validateOrderFields = (item) => {
    console.log('validation object', item);
    if (Object.keys(item?.orderBy || {}).length > 0) {
      if (item?.orderBy?.orderBy === '') {
        return {
          status: true,
          error: 'Order By field is mandatory!'
        }
      } else if (item?.orderBy?.billingDate !== '' && item?.equipmentDetails?.chassis === '') {
        return {
          status: true,
          error: 'Chasis field is mandatory!!'
        }
      } else if (item?.orderBy?.billingDate !== '' && item?.pickup?.returnDate === '') {
        const confirm = window.confirm('Are you sure? Unit has not been returned');
        if (!confirm) {
          return { 
            status: true,
            error: 'Chasis field is mandatory!!'
          }
        }
      }

      if (item?.orderBy?.billingDate !== '' && item?.orderBy?.edi210 === 'Y') {
        if(item?.shipper?.name === '' || item?.shipper?.address === '' || item?.shipper?.city === '' || item?.shipper?.state === '' || item?.shipper?.zip === ''){
          return {
            status: true,
            error: 'Shipper/Consignee details are mandatory!'
          }
        }
      }
    }
    if (item.stops[0]?.name === '' || item.stops[0]?.address === '' || item.stops[0]?.city === '') {
      return {
        status: true,
        error: 'Stops - Name, Address & City fields are mandatory!'
      }
    }

    let stopTruckErrMsg = '';
    item.stops.map((e) => {
      if(!isZeroOrEmpty(e.arriveTruck) && e.arriveTruckStatus === ''){
        stopTruckErrMsg = `Stops [${e.stop}] - Arriver Truck Status field is mandatory!!`;
        return false;
      } else if(isZeroOrEmpty(e.arriveTruck) && e.arriveTruckStatus !== ''){
        stopTruckErrMsg = `Stops [${e.stop}] - Arrive Truck field is mandatory!!`
        return false;
      } else if(!isZeroOrEmpty(e.departTruck) && e.departTruckStatus === ''){
        stopTruckErrMsg = `Stops [${e.stop}] - Depart Truck Status field is mandatory!!`
        return false;
      } else if(isZeroOrEmpty(e.departTruck) && e.departTruckStatus !== '') {
        stopTruckErrMsg = `Stops [${e.stop}] - Depart Truck field is mandatory!!`
        return false;
      } else if (!isZeroOrEmpty(e.arriveTruck) && e.arriveTruckStatus === 'C' && isZeroOrEmpty(e.arriveDate)){
        stopTruckErrMsg = `Stops [${e.stop}] - Arrive Date field is mandatory!!`
        return false;
      } else if (!isZeroOrEmpty(e.departTruck) && e.departTruckStatus === 'C' && isZeroOrEmpty(e.departDate)){
        stopTruckErrMsg = `Stops [${e.stop}] - Depart Date field is mandatory!!`
        return false;
      }else if ((!isZeroOrEmpty(e.departTruck)  || !isZeroOrEmpty(e.arriveTruck) ) && isZeroOrEmpty(e.appointmentDate)){
        stopTruckErrMsg = `Stops [${e.stop}] - Appointment Date field is mandatory!!`
        return false;
      }
    })

    if(stopTruckErrMsg !== ''){
      return {
        status: true,
        error: stopTruckErrMsg
      }
    }

    let rates = normalizeRates(item)
    let ratesErrMsg = '';
    rates.map((e) => {
      const accountLine = {L: 'Linehaul', A:'Accessorial'}
      const payableRecievable  = {P: 'Payables', R:'Receivables'}
      if(isZeroOrEmpty(e.glCode)){
        ratesErrMsg = `Rates&Charges [${accountLine[e.accountLine]}][${payableRecievable[e.payableRecievable]}] - GL Code is mandatory!!`;
        return false;
      } else if(item?.orderBy?.billingDate !== '' && e.amount == 0){
        ratesErrMsg = `Rates&Charges [${accountLine[e.accountLine]}][${payableRecievable[e.payableRecievable]}] - Receivables line amount should not be zero!!`;
        return false;
      }
    });

    if(ratesErrMsg !== ''){
      return {
        status: true,
        error: ratesErrMsg
      }
    }

    return {
      status: false,
      error: ""
    }
  }


  
 export  const validateTruckDriverFieldsInStop=(stops)=>{
    const allTruckDriverError = [];
    let firstStopHandled = false;
stops.forEach(currentStop => {
  //due to legacy i handle stop 0 here for this firstStopHandled is check if stop start from 0  
    if (!firstStopHandled && (currentStop.newStop === 1 || currentStop.newStop === 0)) {
        // Check departure truck status for the first stop
        if (currentStop.departTruckStatus !== "T") {
            return; // Continue to the next stop
        } else {
            // Check if arrival truck details are incomplete
            if (!currentStop.arriveTruck  && !currentStop.arriveTruckStatus) {
                const truckDriverError = {
                    stopNo: currentStop.newStop,
                    stopError: "Cannot enter departure truck details at this stop. Please complete arrival truck details for this stop."
                };
                allTruckDriverError.push(truckDriverError);
            }
        }
        firstStopHandled = true;
    } else {
        // For stops after the first one
        if ((currentStop.arriveTruckStatus === "A" || !currentStop.arriveTruckStatus) &&
         (currentStop.departTruckStatus === "A" || !currentStop.departTruckStatus) ){
            return; // Continue to the next stop
        } else {
            // Find the previous stop
            const previousStopId = currentStop.newStop - 1;
            const previousStop = stops.find(stop => stop.newStop === previousStopId);

                        // Check if arrival truck details are incomplete for the current stop
                        if (!currentStop.arriveTruck && !currentStop.arriveTruckStatus && currentStop.departTruck && currentStop.departTruckStatus){
                            const truckDriverError = {
                                stopNo: currentStop.newStop,
                                stopError: "Cannot enter departure truck details at this stop. Please complete arrival truck details for this stop."
                            };
                            allTruckDriverError.push(truckDriverError);
                        }

            // Check if previous stop's departure truck details are incomplete
            if (previousStop && previousStop.departTruck && previousStop.departTruckStatus ) {
                return; // Continue to the next stop
            } else {
                const truckDriverError = {
                    stopNo: currentStop.newStop,
                    stopError: "Cannot enter arrival truck details at this stop. Please complete previous stop departure truck details."
                };
                allTruckDriverError.push(truckDriverError);
            }
        }
    }
});

return allTruckDriverError
  }

