import { UserTypes } from "../types/userTypes"
import { getAllLoads, getDriverList, getLocationList } from "./loadsActions";
import { ThunkDispatch as Dispatch } from 'redux-thunk'
import { setToken } from './../../utils/cookie';
const login = (loggedIn: boolean) => {
    return {
        type: UserTypes.LOGIN,
        payload: loggedIn
    }
}

export const refreshLogin = () => {
    return (dispatch: Dispatch<any, any, any>) => {
        init(dispatch);
    }
}

export const loginReq = (tokenId: string, refreshToken: string): any => {
    return (dispatch: Dispatch<any, any, any>) => {
        setToken(tokenId, refreshToken);
        init(dispatch);
    }
}

const init = (dispatch: Dispatch<any, any, any>) => {
    dispatch(login(true));
    // dispatch(getAllLoads());
    // dispatch(getDriverList());
    dispatch(getLocationList());
}

export const logout = () => {
    return {
        type: UserTypes.LOGOUT,
    }
}

