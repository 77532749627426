import { useEffect, useState } from "react";

import {
  Paper,
  TableContainer,
  Box,
  TextField,
  Button,
  InputAdornment,
} from "@mui/material";
import {
  DataGridPro,
  GridActionsCellItem,
  GridRowModes,
} from "@mui/x-data-grid-pro";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useSelector, useDispatch } from "react-redux";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { useHistory } from "react-router";
import CustomDateRangePicker from "../../components/CustomDateRangePicker";
import {
  formatedDateRangeFunc,
  formatDateRange,
} from "../../utils/global-functions";

import ClearIcon from "@mui/icons-material/Clear";
import {getOrderType} from "../../utils/getOrderType"
const tableColumns = [
  {
    field: "controlNumber",
    headerName: "Control",
    headerClassName: "MuiDataGrid-columnHeaders",
    flex: 1,
    valueFormatter: (params) => decodeURIComponent(params.value),
    minWidth: 100,
  },
  {
    field: "orderType",
    headerName: "I/O",
    headerClassName: "MuiDataGrid-columnHeaders",
    flex: 0.5,
    valueFormatter: (params) => getOrderType(params.value),
    minWidth: 50,
  },
  {
    field: "returnDate",
    headerName: "Return Date",
    headerClassName: "MuiDataGrid-columnHeaders",
    flex: 1,
    minWidth: 100,
  },
  {
    field: "unitNumber",
    headerName: "Unit",
    headerClassName: "MuiDataGrid-columnHeaders",
    flex: 1,
    minWidth: 100,
  },
  {
    field: "shipperAgentCode",
    headerName: "Order By Name",
    headerClassName: "MuiDataGrid-columnHeaders",
    flex: 1,
    minWidth: 100,
  },
  {
    field: "reference",
    headerName: "Reference",
    headerClassName: "MuiDataGrid-columnHeaders",
    flex: 1,
    minWidth: 100,
  },
  // { field: 'stop_city', headerName: 'stop city', headerClassName: 'MuiDataGrid-columnHeaders',  flex: 1 ,minWidth:100},
  {
    field: "shipperOrConsShipper",
    headerName: "Shipper Name",
    headerClassName: "MuiDataGrid-columnHeaders",
    flex: 1,
    minWidth: 100,
  },
  {
    field: "pn",
    headerName: "PN",
    headerClassName: "MuiDataGrid-columnHeaders",
    flex: 1,
    minWidth: 100,
  },
  {
    field: "pos",
    headerName: "POS",
    headerClassName: "MuiDataGrid-columnHeaders",
    flex: 1,
    minWidth: 100,
  },
  {
    field: "tir",
    headerName: "TIR",
    headerClassName: "MuiDataGrid-columnHeaders",
    flex: 1,
    minWidth: 100,
  },
];
export default function CompletedOrderTable(props) {
  const {
    rows,
    rowsCount,
    singleCompletedOrderData,
    returnDateFrom,
    setReturnDateFrom,
    completedOrderTableApiCall,
    sortModel,
    handlePageChange,
    handleSortChange,
    isLoading,
    paginationModel,
  } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const [searchText, setSearchText] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  // const [templateSearchError, setTemplateSearchError] = useState('')
  const open = Boolean(anchorEl);
  const popOverID = open ? "new-order-popover" : undefined;
  const [onbaseLink, setOnbaseLink] = useState("");
  const [isPickerOpen, setIsPickerOpen] = useState(false);

  const ordersLocation = useSelector(
    ({ orderReducer }) => orderReducer.ordersLocation
  );
  const handleSearchText = (event) => {
    setSearchText(event);
    if (event === "") {
      handleSearch("");
    }
  };
  const handleSearch = (searchNameText) => {

    completedOrderTableApiCall({
      page: 0,
      pageSize: paginationModel.pageSize,
      sortModel,
      isSearch: searchNameText,
    });
  };

  const openOrder = (params) => {
    singleCompletedOrderData(params.row.controlNumber);

    const onBaseLink = `http://con-onbase.eaglegroup.com/AppNet/docpop/docpop.aspx?KT102_0_0_0=BC${ordersLocation?.locationNumber
      .toString()
      .padStart(3, "0")}${params.row.controlNumber
        .toString()
        .padStart(7, "0")}00&clienttype=html&keytype=`;
    setOnbaseLink(onBaseLink);
    // history.push(`/orders/${params.row.locationNumber}/${params.row.controlNumber}`)
  };
  const handleClosePicker = () => {
    setIsPickerOpen(false); // Close picker
  };
  const handleAccept = (index, dateValue) => {
    const { formattedStartDate, formattedEndDate } = formatDateRange(dateValue);
    const formatDate = formattedStartDate + "|" + formattedEndDate;
    setReturnDateFrom(formatDate);
    handleClosePicker();
  };
  return (
    <Paper
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "40px",
          backgroundColor: "#E4EEFA",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <label style={{ fontSize: "14px", color: "#091DA3" }}>
          Completed Order
        </label>
      </div>

      <div
        style={{
          width: "100%",
          height: "50px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          padding: "10px",
        }}
      >
        <div
          style={{
            width: "60%",
            height: "40px",
            display: "flex",
            alignItems: "center",
            paddingRight: "5px",
          }}
        >
          <TextField
            label="Search"
            margin="dense"
            style={{ height: "40px", width: "100%", fontSize: "10px" }}
            size="small"
            inputProps={{ style: { fontSize: 10 } }}
            InputLabelProps={{ style: { fontSize: 14, height: 40 } }}
            value={searchText}
            // onChange={(event) => { setSearchText(event.target.value) }}
            onChange={(event) => {
              handleSearchText(event.target.value);
            }}
            onKeyPress={(event) => {
              event.key === "Enter" && handleSearch(event.target.value);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton
                    onClick={() => {
                      searchText ? handleSearchText("") : handleSearch();
                    }}
                  >
                    {searchText ? <CloseIcon /> : <SearchIcon />}
                  </IconButton>
                  {/* <IconButton onClick={() => { handleSearch() }}>
                                        <SearchIcon />
                                    </IconButton> */}
                </InputAdornment>
              ),
            }}
          />
           </div>
          <div style={{ position: "relative" }}>
            <TextField
              autoComplete="off"
              label="Return Date"
              disablePortal
              size="small"
              className="autocomplestyl"
              id="combo-box-demo"
              value={formatedDateRangeFunc(returnDateFrom) || ""}
              // value={returnDateFrom.startDate}
              onClick={() => setIsPickerOpen(true)}
              readOnly // Makes the field read-only, only clickable
              variant="outlined"
              style={{ cursor: "pointer", width: "100%" }} // Styling for better UI
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="clear date"
                      onClick={(event) => {
                        event.stopPropagation(); // Prevent calendar from opening
                        setReturnDateFrom(null); // Clear the date
                      }}
                    >
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {isPickerOpen && (
              <div
                style={{
                  position: "absolute",
                  top: "-10%",
                  left: "10%",
                  width: "max-content",
                  zIndex: 1000,
                  background: "#fff",
                  boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
                }}
              >
                <CustomDateRangePicker
                  indexValue={0}
                  defaultDateRangeValue={returnDateFrom ? returnDateFrom : ""}
                  onDateSelect={handleAccept}
                  handleCloseDatePicker={handleClosePicker}
                  isRestrict={1}
                />
              </div>
            )}
          </div>
       
      </div>

      <div style={{ width: "100%", height: `calc(100vh - 200px)` }}>
        <TableContainer
          component={Paper}
          style={{ width: "100%", height: "100%" }}
        >
          <Box
            sx={{
              width: "100%",
              overflowX: "auto",
              height: "100%",
              "& .actions": {
                color: "text.secondary",
              },
              "& .textPrimary": {
                color: "text.primary",
              },
              "& .tableTH": {
                fontWeight: 700,
              },
              "& .MuiDataGrid-columnHeaders": {
                // backgroundColor: "rgba(9, 29, 163, 1)",
                backgroundColor: "rgb(11, 67, 135)",
                color: "rgba(255, 255, 255, 1)",
                fontSize: "12px",
              },
              "& .MuiDataGrid-sortIcon": {
                opacity: 1,
                color: "rgba(255, 255, 255, 1)",
              },
              "& .MuiDataGrid-menuIconButton": {
                opacity: 1,
                color: "rgba(255, 255, 255, 1)",
              },
              "& .super-app-theme--cell": {
                backgroundColor: "white",
                fontSize: "11px",
              },
              "& .selected-row": {
                backgroundColor: "#D3D3D3 !important", // Highlight selected row
              },
            }}
          >
            <DataGridPro
              rows={rows}
              rowCount={rowsCount}
              columns={tableColumns}
              loading={isLoading}
              getRowId={(row) => `${row.locationNumber}-${row.controlNumber}`}
              onRowClick={openOrder}
              // sortingMode="server"
              // onSortModelChange={handleSortChange}
              // paginationMode="server"
              // pagination={true}
              // paginationModel={paginationModel}
              // pageSizeOptions={[10, 50, 100]}
              // onPaginationModelChange={(params) => {
              //   handlePageChange(params);
              // }}
              // initialState={{
              //   pagination: {
              //     paginationModel: {
              //       pageSize: paginationModel.pageSize,
              //       page: paginationModel.page,
              //     },
              //   },
              // }}
              pagination={true}
              pageSizeOptions={[10, 25, 50]}
              sortingOrder={['desc', 'asc']}
              initialState={{
                  pagination: { paginationModel: { pageSize: 100, page: 0 } },
                  sortModel: [
                      {
                          field: 'controlNumber',
                          sort: 'desc',
                      },
                  ],
              }}
             
            />
          </Box>
        </TableContainer>
      </div>

      {/* Iframe at the bottom */}
      {onbaseLink ? (
        <Box
          sx={{
            width: "100%",
            height: "300px",
            border: "1px solid #ddd",
            marginTop: "10px",
          }}
        >
          <iframe
            src={onbaseLink}
            style={{ width: "100%", height: "100%", border: "none" }}
            title="Document Viewer"
          />
        </Box>
      ) : null}
    </Paper>
  );
}
