import { useEffect, useState } from 'react';
import {
    Stack,
    TextField,
    IconButton,
    TableCell,
    TableRow
} from "@mui/material";

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
// import custom date picker
import CustomDatePicker from '../../../../components/CustomDatePicker';
import TrucksAutoComplete from './TrucksAutoComplete';
import { calAmountByPer, sanitizeAmountField, getDate, sanitizePercentageField, convertDateToMDY } from '../CommonFn';

export default function SubItemTableRow(props) {
    const {
        rowIndex,
        row,
        handleLineItemDelete,
        handleExistingRow,
        isTemplateScreen
    } = props;

    const [isEditModeOn, setIsEditModeOn] = useState(false);
    const [payableRow, setPayableRow] = useState({ ...row.payable?.subItems?.[0], processedDate: row.payable?.subItems?.[0]?.processedDate || '' } || {});
    const [receivablesRow, setRecievablesRow] = useState(row.receivable?.subItems?.[0] || {});
    // state to disable editable fields
    const [disablePayableRowOnSplit, setDisablePayableRowOnSplit] = useState((row.payable?.splitItems?.length > 0) ? true : false)

    const [disablePayableRow, setDisablePayableRow] = useState(
        (
            (Object.keys(row?.payable?.subItems?.[0] || {})).length === 0 ||
            (typeof row?.payable?.subItems?.[0]?.isFreeLockPayable !== 'undefined' && row?.payable?.subItems?.[0]?.isFreeLockPayable === true) ||
            (typeof row?.payable?.subItems?.[0]?.isLockPayable !== 'undefined' && row?.payable?.subItems?.[0]?.isLockPayable === true)) ? true : false
    );
    const [disableReceivableRow, setDisableReceivableRow] = useState(
        ((Object.keys(row?.receivable?.subItems?.[0] || {}).length === 0 || row?.receivable?.subItems?.[0]?.isFreeLockReceivable || row?.receivable?.subItems?.[0]?.isLockReceivable)) ? true : false
    );

    const handleSave = () => {
        setIsEditModeOn(false);
        handleExistingRow({
            receivable: { ...row.receivable, subItems: [receivablesRow] },
            payable: { ...row.payable, subItems: [payableRow] }
        }, rowIndex);
    }

    // handle delete of sub items based on lineNumber
    const handleReceivableDelete = async () => {
        let confirmAlert = window.confirm('Are you sure?');
        if (confirmAlert) {
            if (row.receivable?.subItems?.[0]?.isServerRecord === true) {
                const delResponse = await handleLineItemDelete(row.receivable?.subItems?.[0]?.lineNumber);
                // console.log('rates charges receivable delete response', delResponse);
                if (delResponse) {
                    handleExistingRow({
                        ...row,
                        receivable: { ...row.receivable, subItems: [] },
                        payable: { ...row.payable, subItems: [{ ...payableRow, payRec: '' }] }
                    }, rowIndex);
                    setPayableRow({ ...payableRow, payRec: '' })
                    setRecievablesRow([])
                }
            } else {
                console.log("isTemplateScreen",isTemplateScreen)
                if(isTemplateScreen){
                    const payabledata = Object.keys(payableRow).length === 0
            ? {}
            : { ...payableRow, payRec: '' };
            handleExistingRow({
                ...row,
                receivable: { ...row.receivable, subItems: [] },
                payable: { ...row.payable, subItems: [payabledata] }
            }, rowIndex);
            setPayableRow(payabledata)
            
                }else{
                handleExistingRow({
                    ...row,
                    receivable: { ...row.receivable, subItems: [] },
                    payable: { ...row.payable, subItems: [{ ...payableRow, payRec: '' }] }
                }, rowIndex);
                setPayableRow({ ...payableRow, payRec: '' })
            }
                setRecievablesRow([])
            }
        }
    }
    const handlePayableDelete = async () => {

        let confirmAlert = window.confirm('Are you sure?');
        if (confirmAlert) {
            if ((typeof row.payable?.subItems?.[0]?.isServerRecord !== 'undefined' && row.payable?.subItems?.[0]?.isServerRecord === true) || row.payable?.subItems?.[0]?.splitItems?.length > 0) {
                const delResponse = await deleteSplitItemsFromServer();
                //console.log('rates charges payable delete response', delResponse);
                if (delResponse) {
                    handleExistingRow({
                        ...row,
                        payable: { ...row.payable, subItems: [] }
                    }, rowIndex);
                    setPayableRow({})
                    setDisablePayableRow(true);
                    setDisablePayableRowOnSplit(false);
                }
            } else {
                handleExistingRow({
                    ...row,
                    payable: { ...row.payable, subItems: [] }
                }, rowIndex)
                setPayableRow({});
                setDisablePayableRow(true);
                setDisablePayableRowOnSplit(false);
            }
        }
    }
    /**
     * Delete split items from server
     */
    const deleteSplitItemsFromServer = async () => {
        let parentPayable = row.payable?.subItems?.[0] || [];
        let subItem = row.payable?.subItems?.[0]?.splitItems || [];
        let splitItemArr = [parentPayable, ...subItem];

        if (splitItemArr.length > 0) {
            let res = await Promise.all(splitItemArr.map(async (item) => {
                if (typeof item?.isServerRecord !== 'undefined' && item?.isServerRecord === true) {
                    return await handleLineItemDelete(item?.lineNumber);
                }
                return false;
            }));
            return res;
        }
        return true;
    }

    // Monitor row changes in parent element
    useEffect(() => {

        if (row.payable?.subItems?.length === 0) {
            setPayableRow({});
            setDisablePayableRow(true)
        } else {
            setPayableRow(row.payable?.subItems?.[0]);
        }
        if (row.payable?.subItems?.[0]?.splitItems?.length > 0) {
            setPayableRow({splitItems:row.payable?.subItems?.[0]?.splitItems});
            setDisablePayableRowOnSplit(true)
        } else {
            setDisablePayableRowOnSplit(false)
        }

        if (row.receivable?.subItems?.length === 0) {
            setRecievablesRow({});
            setDisableReceivableRow(true)
        }
        if (row.receivable?.isFreeLockReceivable === false && row.receivable.isFreeLockReceivable === true) {
            setDisableReceivableRow(false)
        }

    }, [row.payable?.subItems, row.receivable?.subItems])

    return (
        <>
            {isEditModeOn ? (
                <TableRow className={`${receivablesRow?.transmitted === 'Y' ? 'tx_r_y' : ''} ${payableRow?.transmitted === 'Y' ? 'tx_p_y' : ''}`}>
                    <TableCell>
                        <Stack direction="row">
                            <IconButton size={`small`} sx={{ width: '25px' }} onClick={() => handleSave()}>
                                <DoneIcon titleAccess='Save Changes' fontSize="small" color="success" />
                            </IconButton>
                            <IconButton size={`small`} sx={{ width: '25px' }} onClick={() => setIsEditModeOn(false)}>
                                <CloseIcon titleAccess='Cancel Changes' fontSize="small" sx={{ color: 'red' }} />
                            </IconButton>
                        </Stack>
                    </TableCell>
                    <TableCell>
                        <TextField
                            type={`text`}
                            variant="standard"
                            value={receivablesRow?.notes}
                            style={{ width: 'fit-content' }}
                            onChange={(e, val) => setRecievablesRow({
                                ...receivablesRow,
                                notes: e.target.value
                            })}
                            disabled={true}
                        />
                    </TableCell>
                    <TableCell>
                        <TextField
                            type={`number`}
                            variant="standard"
                            value={receivablesRow?.glCode}
                            style={{ width: 'fit-content' }}
                            onChange={(e, val) => setRecievablesRow({
                                ...receivablesRow,
                                glCode: e.target.value
                            })}
                            disabled={true}
                        />
                    </TableCell>
                    <TableCell>
                        <TextField
                            type={`text`}
                            variant="standard"
                            value={receivablesRow?.description}
                            style={{ width: 'fit-content' }}
                            onChange={(e, val) => setRecievablesRow({
                                ...receivablesRow,
                                description: e.target.value
                            })}
                            disabled={disableReceivableRow}
                        />
                    </TableCell>
                    <TableCell>
                        <TextField
                            type={`number`}
                            variant="standard"
                            value={receivablesRow?.unitRate}
                            style={{ width: 'fit-content' }}
                            onChange={(e, val) => setRecievablesRow({
                                ...receivablesRow,
                                unitRate: e.target.value,
                                amount: calAmountByPer(e.target.value, receivablesRow.units)
                            })}
                            disabled={disableReceivableRow}
                        />
                    </TableCell>
                    <TableCell>
                        <TextField
                            type={`number`}
                            variant="standard"
                            value={receivablesRow?.units}
                            style={{ width: 'fit-content' }}
                            onChange={(e, val) => setRecievablesRow({
                                ...receivablesRow,
                                units: e.target.value,
                                amount: calAmountByPer(receivablesRow.unitRate, e.target.value)
                            })}
                            disabled={disableReceivableRow}
                        />
                    </TableCell>
                    <TableCell>
                        <TextField
                            type={`number`}
                            variant="standard"
                            value={receivablesRow?.amount}
                            style={{ width: 'fit-content' }}
                            onChange={(e, val) => setRecievablesRow({
                                ...receivablesRow,
                                amount: e.target.value
                            })}
                            disabled={disableReceivableRow}
                        />
                    </TableCell>
                    <TableCell>
                        <TextField
                            type={`text`}
                            variant="standard"
                            value={payableRow?.description}
                            style={{ width: 'fit-content' }}
                            onChange={(e, val) => setPayableRow({
                                ...payableRow,
                                description: e.target.value
                            })}
                            disabled={disablePayableRow || disablePayableRowOnSplit}
                        />
                    </TableCell>
                    <TableCell>
                        <TextField
                            type={`number`}
                            variant="standard"
                            value={payableRow?.glCode}
                            disabled={true}
                            style={{ width: 'fit-content' }}
                            onChange={(e, val) => setPayableRow({
                                ...payableRow,
                                glCode: e.target.value
                            })}
                        />
                    </TableCell>
                    <TableCell>
                        <TextField
                            type={`number`}
                            variant="standard"
                            value={payableRow?.unitRate}
                            style={{ width: 'fit-content' }}
                            onChange={(e, val) => setPayableRow({
                                ...payableRow,
                                unitRate: e.target.value,
                                amount: calAmountByPer(e.target.value, payableRow.units)
                            })}
                            disabled={disablePayableRow || disablePayableRowOnSplit}
                        />
                    </TableCell>
                    <TableCell>
                        <TextField
                            type={`number`}
                            variant="standard"
                            value={payableRow?.units}
                            style={{ width: 'fit-content' }}
                            onChange={(e, val) => setPayableRow({
                                ...payableRow,
                                units: e.target.value,
                                amount: calAmountByPer(payableRow.unitRate, e.target.value)
                            })}
                            disabled={disablePayableRow || disablePayableRowOnSplit}
                        />
                    </TableCell>
                    <TableCell>
                        <TextField
                            type={`number`}
                            variant="standard"
                            value={payableRow?.amount}
                            disabled={true}
                            style={{ width: 'fit-content' }}
                            onChange={(e, val) => setPayableRow({
                                ...payableRow,
                                amount: e.target.value
                            })}
                        />
                    </TableCell>
                    <TableCell>
                        {/* <TextField
                            type={`text`}
                            variant="standard"
                            value={payableRow?.truckNumber}
                            style={{ width: 'fit-content' }}
                            onChange={(e, val) => setPayableRow({
                                ...payableRow,
                                truckNumber: e.target.value
                            })}
                            disabled={disablePayableRow || disablePayableRowOnSplit}
                            inputProps={{ maxLength: 5 }}
                        /> */}
                        <TrucksAutoComplete
                            valueChange={(val) => {
                                if (val !== '') {
                                    setPayableRow({
                                        ...payableRow,
                                        truckNumber: val,
                                    })

                                } else if (val === '') {
                                    setPayableRow({
                                        ...payableRow,
                                        truckNumber: '',
                                    })
                                }
                            }
                            }
                            existingValue={payableRow?.truckNumber || ''}
                            disabled={disablePayableRow || disablePayableRowOnSplit}
                        />
                    </TableCell>
                    <TableCell>
                        <CustomDatePicker
                            variant="standard"
                            value={convertDateToMDY(payableRow?.processedDate) || ''}
                            style={{ width: 'fit-content' }}
                            onValueChange={(val) => {
                                if (val === '') {
                                    setDisablePayableRow(false);
                                    setPayableRow({
                                        ...payableRow,
                                        processedDate: ''
                                    })
                                } else {
                                    setPayableRow({
                                        ...payableRow,
                                        processedDate: val
                                    })
                                }
                            }
                            }
                            disabled={(row.payable?.isLockPayable === true || disablePayableRowOnSplit) ? true : false}
                        />
                    </TableCell>
                    <TableCell>
                    </TableCell>
                </TableRow>
            ) : (
                <>
                    {((Object.keys(payableRow || {}).length === 0) && !disablePayableRowOnSplit && (Object.keys(receivablesRow || {}).length === 0)) ? null : (
                        <TableRow className={`${receivablesRow?.transmitted === 'Y' ? 'tx_r_y' : ''} ${payableRow?.transmitted === 'Y' ? 'tx_p_y' : ''}`}>
                            <TableCell>
                                <Stack direction="row">
                                    <IconButton size={`small`} sx={{ width: '25px' }} onClick={() => setIsEditModeOn(true)}>
                                        <EditIcon titleAccess='Edit' color="black" />
                                    </IconButton>
                                    <IconButton disabled={(receivablesRow?.length === 0 || disableReceivableRow) ? true : false} size={`small`} sx={{ width: '25px' }} onClick={() => handleReceivableDelete()}>
                                        <DeleteIcon titleAccess='Delete FSC Recievable' fontSize="small" color="black" />
                                    </IconButton>
                                </Stack>
                            </TableCell>
                            <TableCell></TableCell>
                            <TableCell align="center">{receivablesRow?.glCode}</TableCell>
                            <TableCell align="center">{receivablesRow?.description}</TableCell>
                            <TableCell align="center">{sanitizeAmountField(receivablesRow?.unitRate)}</TableCell>
                            <TableCell align="center">{sanitizePercentageField(receivablesRow?.units)}</TableCell>
                            <TableCell align="center">{sanitizeAmountField(receivablesRow?.amount)}</TableCell>
                            <TableCell align="center" className={(disablePayableRowOnSplit) ? 'disabledCellOnSplit' : ''}>{payableRow?.description}</TableCell>
                            <TableCell align="center" className={(disablePayableRowOnSplit) ? 'disabledCellOnSplit' : ''}>{payableRow?.glCode}</TableCell>
                            <TableCell align="center" className={(disablePayableRowOnSplit) ? 'disabledCellOnSplit' : ''}>{sanitizeAmountField(payableRow?.unitRate)}</TableCell>
                            <TableCell align="center" className={(disablePayableRowOnSplit) ? 'disabledCellOnSplit' : ''}>{sanitizePercentageField(payableRow?.units)}</TableCell>
                            <TableCell align="center" className={(disablePayableRowOnSplit) ? 'disabledCellOnSplit' : ''}>{sanitizeAmountField(payableRow?.amount)}</TableCell>
                            <TableCell align="center" className={(disablePayableRowOnSplit) ? 'disabledCellOnSplit' : ''}>{payableRow?.truckNumber}</TableCell>
                            <TableCell align="center" className={(disablePayableRowOnSplit) ? 'disabledCellOnSplit' : ''}>{convertDateToMDY(payableRow?.processedDate)}</TableCell>
                            <TableCell>
                                <Stack direction="row" marginLeft={3} sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}>
                                    <IconButton disabled={(disablePayableRow) ? true : false} size={`small`} sx={{ width: '25px' }} onClick={() => handlePayableDelete()}>
                                        <DeleteIcon titleAccess='Delete FSC Payable' fontSize="small" color="black" />
                                    </IconButton>
                                </Stack>
                            </TableCell>
                        </TableRow>
                    )}
                </>
            )}
        </>
    );
}